import * as React from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

export function useInternalLink() {
  const { manifestId } = useParams<{ manifestId: string }>();
  const { user } = queryString.parse(window.location.search);
  const userParam = user ? `?user=${user}` : '';

  const internalLink = React.useCallback(
    (url: string) => {
      return manifestId
        ? `/${manifestId}${url}${userParam}`
        : `${url}${userParam}`;
    },
    [manifestId, userParam],
  );

  return { internalLink };
}
