import * as React from 'react';
import HotspotImage from './HotspotImage';
import Carousel from '../Carousel';
import { SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type HotspotProps = {
  hotspot: Quintus.Hotspot;
};

const Hotspot: React.FC<HotspotProps> = ({ hotspot }) => {
  if (!hotspot) {
    return null;
  }

  return (
    <>
      {hotspot.imageMaps.length > 1 ? (
        <Carousel>
          {hotspot.imageMaps.map((image, index) => (
            <SwiperSlide
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              key={index}
            >
              <HotspotImage image={image} key={index} />
            </SwiperSlide>
          ))}
        </Carousel>
      ) : (
        <ImageWrapper>
          <HotspotImage image={hotspot.imageMaps[0]} />
        </ImageWrapper>
      )}
    </>
  );
};

export default Hotspot;
