import React from 'react';
import styled from 'styled-components';
import Footer from '../Footer';
import Header from '../Header';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Content = styled.div<{ withoutBgColor?: boolean }>`
  flex: 1;

  //Padding to make up for when scrollbar appears
  @media (min-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding-left: calc(100vw - 100%);
  }
  justify-content: top;
  flex-direction: column;
  display: flex;
  background-color: ${({ withoutBgColor, theme }) =>
    !withoutBgColor && theme.colors.quintusLightGray};
`;

type PageProps = {
  hideSearch?: boolean;
  withoutBgColor?: boolean;
  hideMenu?: boolean;
  hideFooter?: boolean;
  initializing?: boolean;
};

const Page: React.FC<PageProps> = props => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <>
      <Wrapper>
        <Header
          hideSearch={props.hideSearch}
          hideMenu={props.hideMenu}
          initializing={props.initializing}
        />
        <Content withoutBgColor={props.withoutBgColor}>
          {props.children}
        </Content>
        {(!props.hideFooter || props.initializing) && <Footer />}
      </Wrapper>
    </>
  );
};
export default Page;
