import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useLinkFromId } from 'src/helpers/link';
import { useFetchBrowse } from 'src/hooks/useFetchData';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.quintusDarkBlue};

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.quintusGreen};
  }
`;

const StyledInternalLink = styled.a`
  color: ${({ theme }) => theme.colors.quintusDarkBlue};

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.quintusGreen};
  }
`;

type AnchorProps = {
  to: string;
  children: React.ReactNode;
  data: string;
};

const Anchor: FC<AnchorProps> = ({ to, data }) => {
  const { data: browseData } = useFetchBrowse();
  const idLink = useLinkFromId(browseData?.data, to);

  //inline links
  if (to.startsWith('#')) {
    return <StyledInternalLink href={to}>{data}</StyledInternalLink>;
  }

  return <StyledLink to={idLink}>{data}</StyledLink>;
};

export default Anchor;
