import * as React from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { useFetchBrowse } from 'src/hooks/useFetchData';
import styled from 'styled-components';
import DrawerMenu from './DrawerMenu';
import { MainContent } from './layout/MainContent';
import { SidebarWrapper } from './layout/SidebarWrapper';
import SecondaryHeader from './SecondaryHeader';
import LanguageDropdown from './LanguageDropdown';

const Logo = styled.img`
  max-width: initial;
  display: inline-block;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    margin-bottom: 5px;
  }
`;

const Logowrapper = styled(Link)`
  color: #242f3a;
  font-weight: bold;
`;

const Icon = styled.img<{ width: string; margin?: string }>`
  cursor: pointer;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin && margin};
`;

const Wrapper = styled.div`
  background-color: ${p => p.theme.colors.quintusWhite};
  padding: 27px 0;
  display: flex;
  justify-content: center;
  width: 100%;

  //Padding to make up for when scrollbar appears
  @media (min-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding-left: calc(100vw - 100%);
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.sizes.containerWide};
  width: 100%;
  align-items: center;

  padding: ${({ theme }) => `0 ${theme.space.md}`};

  //Padding to make up for when scrollbar appears
  @media (max-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding-right: calc(100% - 100vw + 24px);
  }
`;

const DrawerWrapper = styled.div`
  //Padding to make up for when scrollbar appears
  @media (min-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding-left: calc(100vw - 100%);
  }
  display: flex;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const SearchIconContainer = styled.div`
  width: 20px;
`;

const StyledSidebarWrapper = styled(SidebarWrapper)`
  margin-top: 0;
  width: auto;
  padding: 0;
  z-index: 999;
`;

const MenuWrapper = styled.ul`
  display: none;

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    display: flex;
    justify-content: center;
  }
`;

const Item = styled.li`
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 260px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    margin: 0 12px;
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: 17px;
  }

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    margin: 5px 0px;
  }
`;

const StyledMainContent = styled(MainContent)`
  flex: 1;
  z-index: 999;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const A = styled(NavLink)`
  font-family: Arial, Helvetica, sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  font-weight: 400;

  &:hover {
    color: ${({ theme }) => theme.colors.quintusGreen};
  }
`;

type HeaderProps = {
  hideSearch?: boolean;
  hideMenu?: boolean;
  initializing?: boolean;
};

const Header: React.FC<HeaderProps> = ({
  hideSearch,
  hideMenu,
  initializing,
}) => {
  const [showDrawerMenu, setShowDrawerMenu] = React.useState(false);
  const [drawerContent, setDrawerContent] = React.useState<'menu' | 'search'>(
    'menu',
  );
  const [showLanguageDropdown, setShowLanguageDropdown] = React.useState(false);
  const toggleDrawerMenu = () => setShowDrawerMenu(!showDrawerMenu);
  const { manifestId } = useParams<{ manifestId: string }>();
  const toggleDropdown = () => setShowLanguageDropdown(!showLanguageDropdown);

  return (
    <>
      <Wrapper>
        <WrapperContent>
          <StyledSidebarWrapper>
            <Logowrapper to={`/${manifestId}`}>
              <Logo
                src="/images/quintuslogoheader.svg"
                alt=""
                height={43}
                width={128}
              />
            </Logowrapper>
          </StyledSidebarWrapper>

          <StyledMainContent>{!hideMenu && <Menu />}</StyledMainContent>
          <IconsWrapper>
            {!hideMenu ? (
              <>
                <SearchIconContainer>
                  {!hideSearch && (
                    <Icon
                      src="/images/search.svg"
                      width="20px"
                      onClick={() => {
                        toggleDrawerMenu();
                        setDrawerContent('search');
                      }}
                    />
                  )}
                </SearchIconContainer>
                <Icon
                  src="/images/menu.svg"
                  width="20px"
                  margin="0 0 0 44px"
                  onClick={() => {
                    toggleDrawerMenu();
                    setDrawerContent('menu');
                  }}
                />
              </>
            ) : (
              <>
                {!initializing && (
                  <Icon
                    src="/images/globe.svg"
                    width="20px"
                    onClick={() =>
                      setShowLanguageDropdown(!showLanguageDropdown)
                    }
                  />
                )}
                <LanguageDropdown
                  isVisible={showLanguageDropdown}
                  toggleDropdown={toggleDropdown}
                />
              </>
            )}
          </IconsWrapper>
        </WrapperContent>
      </Wrapper>
      {(!hideMenu || initializing) && <SecondaryHeader />}
      {showDrawerMenu && (
        <DrawerWrapper>
          <DrawerMenu
            visible={showDrawerMenu}
            onClose={toggleDrawerMenu}
            drawerContent={drawerContent}
          />
        </DrawerWrapper>
      )}
    </>
  );
};

const Menu: React.FC = () => {
  const { data } = useFetchBrowse();
  const { pathname } = useLocation();
  const { manifestId } = useParams<{ manifestId: string }>();
  const isActive = (url: string) => {
    return pathname.split('/').length > 2
      ? pathname.split('/').includes(url)
      : false;
  };
  return (
    <MenuWrapper>
      {data?.data?.map((item, index) => (
        <Item key={index}>
          <A
            to={`/${manifestId}/browse/${item.topicId}`}
            style={
              isActive(item.topicId) === true ? { fontWeight: 900 } : undefined
            }
          >
            {item.title}
          </A>
        </Item>
      ))}
    </MenuWrapper>
  );
};

export default Header;
