import React from 'react';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import Hotspot from 'src/components/hotspot/Hotspot';
import H1 from 'src/components/typography/H1';
import styled from 'styled-components';
import { useFetchHotspots } from '../hooks/useFetchData';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import { MainContent } from '../components/layout/MainContent';

const StyledH1 = styled(H1)`
  margin-top: ${({ theme }) => theme.space.m};
  margin-bottom: ${({ theme }) => theme.space.xxl};
`;

const Hotspots: React.FC = () => {
  const { hotspotId } = useParams<{ hotspotId: string }>();
  const { error, data } = useFetchHotspots();

  const getHotspot = () => {
    return data.find(
      item => item.id.slice(0, item.id.indexOf('.')) === hotspotId,
    );
  };

  return (
    <Page>
      <PageContentWrapper flex={1} centerContent={true}>
        {error || !data ? null : (
          <>
            <MainContent>
              <Breadcrumbs
                breadcrumbs={[
                  { label: getHotspot().title, url: `/hotspot/${hotspotId}` },
                ]}
              />
              <StyledH1>{getHotspot().title}</StyledH1>
            </MainContent>
            <MainContent>
              <Hotspot hotspot={getHotspot()} />
            </MainContent>
          </>
        )}
      </PageContentWrapper>
    </Page>
  );
};

export default Hotspots;
