import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';
import styled from 'styled-components';

SwiperCore.use([Navigation, Pagination]);

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  align-self: center;
  width: 951px;
  max-width: 100%;

  .swiper-pagination-bullet {
    cursor: pointer;
    border-radius: 1.63px;
    border-width: 1.63px;
    border-color: ${({ theme }) => theme.colors.quintusDarkBlue};
    background: rgba(255, 255, 255, 0.1);
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.quintusDarkBlue};
  }
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
`;

const NavigationItem = styled.div`
  position: absolute;
  z-index: 8;
  margin-left: -31px;
  cursor: pointer;
  &:nth-child(2) {
    right: 0;
    margin-right: -31px;
    margin-left: 0px;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    margin-left: -18px;

    &:nth-child(2) {
      margin-right: -18px;
    }
  }
`;

const Carousel: React.FC = ({ children }) => {
  const params = {
    slidesPerView: 1,
    loop: true,
    pagination: true,
  };

  return (
    <Wrapper>
      <>
        <NavigationItem className="prev">
          <img src="/images/arrow-left.svg" alt="" />
        </NavigationItem>
        <NavigationItem className="next">
          <img src="/images/arrow-right.svg" alt="" />
        </NavigationItem>
        <StyledSwiper
          {...params}
          navigation={{
            prevEl: '.prev',
            nextEl: '.next',
          }}
        >
          {children}
        </StyledSwiper>
      </>
    </Wrapper>
  );
};

export default Carousel;
