import { useFetchBrowse } from 'src/hooks/useFetchData';

export function useTopLevelFromMapfile(mapfile: Quintus.MapFileType):
  | {
      title: string;
      topicId: string;
    }
  | undefined {
  const { data } = useFetchBrowse();
  const item = data?.data.find(item =>
    item.title.replace("'", '').includes(mapfile),
  );

  if (item) {
    return {
      title: item.title,
      topicId: item.topicId,
    };
  }

  return item;
}
