import styled from 'styled-components';

export default styled.h3`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.lineHeights.s};
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
`;
