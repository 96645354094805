import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { renewAccessToken } from 'src/hooks/useAuth';

const api = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
});
const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
});

api.interceptors.request.use(
  async response => {
    let decodedAccess = null;
    let decodedRefresh = null;
    const token = getToken('token');
    const refreshToken = getToken('refreshToken');

    if (token) {
      decodedAccess = jwt_decode<Quintus.JwtToken>(token);
    }
    if (refreshToken) {
      decodedRefresh = jwt_decode<Quintus.JwtToken>(refreshToken);
    }
    if (!token) {
      return Promise.reject('Invalid token');
    }
    // if access token not valid
    if (token && Date.now() / 1000 >= decodedAccess.exp) {
      //if refresh valid
      if (refreshToken && Date.now() / 1000 < decodedRefresh.exp) {
        //get new access
        const refresh = await renewAccessToken();
        if (refresh.status === 200) {
          setToken('token', refresh.data.token);
        } else {
          return Promise.reject('Invalid token');
        }
      } else {
        return Promise.reject('Token expired');
      }
    }

    response.headers = {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
      Authorization: token && `Bearer ${getToken('token')}`,
    };

    return response;
  },
  err => Promise.reject(err),
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      return error.response;
    } else {
      throw new Error(error);
    }
  },
);

authApi.interceptors.request.use(
  response => {
    return response;
  },
  err => Promise.reject(err),
);

authApi.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      return error.response;
    }
  },
);

type Token = 'token' | 'storageToken' | 'refreshToken';

function setToken(key: Token, token: string): void {
  const currentToken = getToken(key);

  if (currentToken !== token) {
    sessionStorage.setItem(key, token);
  }
}

function getToken(key: Token): string | null {
  return sessionStorage.getItem(key);
}

function clearTokens(): void {
  [
    'product',
    'productTitle',
    'token',
    'refreshToken',
    'storageToken',
    'config',
  ].forEach(token => sessionStorage.removeItem(token));
}

export default api;
export { setToken, getToken, clearTokens, authApi };
