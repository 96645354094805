function getCrumbs(child: Quintus.Node, topicId: string, topLevel: string) {
  const title = child.title.replace(/ /g, '');
  const breadcrumbs = {
    [title]: [
      {
        label: child.title,
        url: child.children
          ? `/browse/${topLevel}/${child.topicId}`
          : `/${topLevel}/topics/${child.topicId}`,
      },
    ],
  };

  let currentNode: Quintus.Node = child.topicId === topicId && child;

  function recurse(childNode: Quintus.Node) {
    const children = childNode.children;

    for (const key in children) {
      if (children[key].topicId === topicId) {
        currentNode = children[key];
      }

      if (breadcrumbs[title] && children[key].topicId === topicId) {
        if (!breadcrumbs[title].find(b => b.label === childNode.title)) {
          breadcrumbs[title].push({
            label: childNode.title,
            url: childNode.children
              ? `/browse/${topLevel}/${childNode.topicId}`
              : `/${topLevel}/topics/${childNode.topicId}`,
          });
        }
        breadcrumbs[title].push({
          label: children[key].title,
          url: children[key].children
            ? `/browse/${topLevel}/${children[key].topicId}`
            : `/${topLevel}/topics/${children[key].topicId}`,
        });
      }

      recurse(children[key]);
    }
  }

  if (child.children && child.topicId !== topicId) {
    recurse(child);
  }

  return { breadcrumbs: breadcrumbs[title], currentNode };
}

export function getChildren(
  data: Quintus.Node,
  childId: string,
  topLevel?: string,
): { nodeData: Quintus.Node | undefined; breadcrumbs: Quintus.BreadCrumb[] } {
  let node = {
    currentNode: undefined,
    breadcrumbs: [],
  };

  data.children.map(child => {
    const { currentNode, breadcrumbs } = getCrumbs(child, childId, topLevel);
    if (currentNode) {
      node = { currentNode, breadcrumbs };
    }
  });

  return { nodeData: node.currentNode, breadcrumbs: node.breadcrumbs };
}

export function getSiblings(
  currentTopicId: string,
  browseList: Quintus.Node,
): Quintus.Node[] {
  if (!browseList || !browseList.children || browseList.children.length < 1) {
    return [];
  }

  const siblings: Quintus.Node[] = [];

  const findSiblings = (
    currentTopicId: string,
    browseList: Quintus.Node,
  ): void => {
    browseList.children.forEach(child => {
      if (child && child.children) {
        findSiblings(currentTopicId, child);
      }

      if (child.topicId === currentTopicId) {
        browseList.children.map(c => {
          siblings.push(c);
        });
      }
    });
  };

  findSiblings(currentTopicId, browseList);
  return siblings;
}

export function getCurrentBrowseItem(
  browseList: Quintus.Node[],
  currentId: string,
): {
  topLevelItem: Quintus.Node | undefined;
  currentBrowseItem: Quintus.Node | undefined;
} {
  if (!browseList) {
    return undefined;
  }
  let currentBrowseItem: Quintus.Node;
  let topLevelItem: Quintus.Node;

  browseList.forEach(topItem => {
    if (topItem.topicId === currentId) {
      currentBrowseItem = topItem;
    }

    const searchBrowseTree = (browse: Quintus.Node[]): void => {
      if (!browse) return;

      browse.forEach(entry => {
        if (entry.topicId === currentId) {
          topLevelItem = topItem;
          currentBrowseItem = entry;
        } else {
          if (entry.children) {
            searchBrowseTree(entry.children);
          }
        }
      });
    };

    searchBrowseTree(topItem.children);
  });

  return { topLevelItem, currentBrowseItem };
}
