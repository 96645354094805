import * as React from 'react';
import { LanguageContext } from 'src/context/LanguageContext';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.quintusDarkBlue};
  position: absolute;
  right: 0px;
  top: 20px;
  padding: 10px 20px;
  width: 120px;
`;

const LanguageList = styled.ul`
  padding-left: 0;
`;

const Language = styled.li<{ active: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.1px;
  color: #fff;
  font-weight: ${({ active }) => (active ? '700' : '400')};
  padding: ${({ theme }) => `${theme.space.xs} 0`};
  cursor: pointer;
`;

type LanguageDropdownProps = {
  isVisible: boolean;
  toggleDropdown: () => void;
};

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  isVisible,
  toggleDropdown,
}) => {
  const { languages, currentLanguage, updateLanguage } =
    React.useContext(LanguageContext);

  if (!isVisible) {
    return null;
  }

  const handleLanguageChange = (languageCode: string) => {
    updateLanguage(languageCode);
    toggleDropdown();
  };

  return (
    <Wrapper>
      <LanguageList>
        {languages?.map((language, index) => (
          <Language
            key={index}
            active={currentLanguage.code === language.code}
            onClick={() => handleLanguageChange(language.code)}
          >
            {language.title}
          </Language>
        ))}
      </LanguageList>
    </Wrapper>
  );
};

export default LanguageDropdown;
