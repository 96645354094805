import * as React from 'react';
import { getChildren } from '../helpers/node';

type NodeProps = {
  node: Quintus.Node | undefined;
  breadcrumbs?: Quintus.BreadCrumb[];
};

export function useNodes(
  data: Quintus.Node | undefined,
  childId: string,
  path: string,
  label?: string,
): NodeProps {
  const [node, setNode] = React.useState<NodeProps>({
    node: undefined,
    breadcrumbs: [],
  });

  React.useEffect(() => {
    const setBredcrumbs = data => {
      const pathBreadcrumb = { label: label, url: `/browse/${path}` };

      if (childId) {
        const { nodeData, breadcrumbs } = getChildren(data, childId, path);
        setNode({
          node: nodeData,
          breadcrumbs: [pathBreadcrumb, ...breadcrumbs],
        });
      } else {
        setNode({ node: data, breadcrumbs: [pathBreadcrumb] });
      }
    };

    if (data !== undefined) {
      setBredcrumbs(data);
    }
  }, [data, childId, path, label]);

  return { node: node.node, breadcrumbs: node.breadcrumbs };
}
