import * as React from 'react';
import { useFetchTopic } from '../hooks/useFetchData';
import Loader from './Loader';
import StringToComponent from './StringToComponent';
import styled from 'styled-components';
import { MainContent } from './layout/MainContent';
import Feedback from './Feedback';

const StyledMainContent = styled(MainContent)`
  margin-top: ${({ theme }) => theme.space.xl};

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    position: initial;
    order: 1;
  }
`;

type TopicContent = {
  topicId: string;
};

const TopicContent: React.FC<TopicContent> = ({ topicId }) => {
  const { isLoading, isError, data } = useFetchTopic(topicId);

  if (!data || isError) {
    return null;
  }

  return (
    <StyledMainContent>
      {isLoading ? <Loader /> : <StringToComponent data={data} />}
      <Feedback />
    </StyledMainContent>
  );
};

export default TopicContent;
