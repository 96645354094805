import * as React from 'react';
import Modal from './Modal';
import H2 from '../typography/H2';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/useTranslation';

const Wrapper = styled.div`
  padding: 0 16px 16px;

  & h2 {
    font-size: ${({ theme }) => theme.fontSizes.modal};
    color: ${({ theme }) => theme.colors.quintusBlue};
    font-style: normal;
    font-weight: 400;
    line-height: ${({ theme }) => theme.lineHeights.modal};
    letter-spacing: 0.05em;
    margin: ${({ theme }) => theme.space.modal} 0;
  }
`;

type ErrorModalProps = {
  errorMessage: boolean;
  closeHandler: () => void;
};

const ErrorModal: React.FC<ErrorModalProps> = ({
  errorMessage,
  closeHandler,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      width={'540px'}
      isVisible={errorMessage}
      onClose={() => closeHandler()}
    >
      <Wrapper>
        <H2>{errorMessage}</H2>
      </Wrapper>
    </Modal>
  );
};

export default ErrorModal;
