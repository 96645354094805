const theme = {
  colors: {
    quintusBlue: '#0070B9',
    quintusDarkBlue: '#023E84',
    quintusMediumGrey: '#49515B',
    quintusTrueBlack: '#000',
    quintusTableBorder: '#A0A0A0',
    quintusWarningYellow: '#FFD442',
    quintusBrown: '#5D5F56',
    quintusGreen: '#47959F',
    quintusAlarmRed: '#D22E2E',
    quintusGray: '#242F3A',
    quintusWhite: '#FFF',
    quintusBlack: '#000',
    quintusCautionBlue: '#8AA8D7',
    quintusLightGray: '#49515B1A',
    quintusGray20: '#D3D5D8',
    quintusGray40: '#A7ACB0',
    quintusListBorder: '#A7ACB0',
    quintusDisabled: '#A1A1A1',
    quintusStartpageBg: '#4D575C',

    infoTypes: {
      service: '#78C2DE',
      instruction: '#FBE400',
      parts: '#B9D3B7',
    },
  },

  space: {
    xxxl: '62px',
    xxl: '55px',
    modal: '46px',
    xl: '40px',
    l: '32px',
    md: '24px',
    m: '20px',
    s: '16px',
    sm: '12px',
    xs: '8px',
    xxs: '4px',
    xxxs: '3px',
    gutter: '20px',
    attention: '21px',
    table: '5px',
  },
  fontSizes: {
    xxxl: '48px',
    xxl: '36px',
    modal: '28px',
    xl: '26px',
    l: '24px',
    ml: '18px',
    m: '16px',
    s: '14px',
    xxs: '13px',
    xxxs: '10px',
  },
  lineHeights: {
    l: '44px',
    modal: '32px',
    m: '30px',
    s: '24px',
    xs: '20.7px',
    xxs: '18px',
    xxxs: '15px',
  },
  fonts: {
    regular: 'Arial, sans-serif',
  },
  radii: {
    l: '10px',
    m: '6px',
    s: '3.85px',
    xs: '2px',
  },
  borderWidths: {
    xl: 6.42,
    l: 4,
    m: 2,
    s: 1,
    attention: 3,
  },
  sizes: {
    containerWide: '1440px',
  },
  screens: {
    l: '1025px',
    md: '768px',
    sm: '640px',
  },
};

export type ThemeType = typeof theme;

export default theme;
