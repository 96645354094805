import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from 'src/context/UserContext';
import { getProductTitle } from 'src/helpers/products';
import styled from 'styled-components';
import { WrapperContent } from './Header';
import ProductSelector from './ProductSelector';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space.xxs} 0`};
  background-color: ${({ theme }) => theme.colors.quintusDarkBlue};

  //Padding to make up for when scrollbar appears
  @media (min-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding-left: calc(100vw - 100%);
  }
`;

const ProductName = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #fff;
  font-weight: 900;
  min-height: 20px;
`;

const ProductSelectorWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 0;
  z-index: 9;
  z-index: 999;
`;

const SecondaryHeader: React.FC = () => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const { hasFullAccess } = React.useContext(UserContext);
  const location = useLocation();

  const toggleDropdown = React.useCallback(() => {
    setShowDropdown(!showDropdown);
  }, [showDropdown]);

  return (
    <Wrapper>
      <WrapperContent
        style={{
          position: 'relative',
        }}
      >
        <ProductName
          onClick={hasFullAccess ? toggleDropdown : null}
          disabled={!hasFullAccess}
        >
          {location.pathname === '/login' || location.pathname === '/'
            ? null
            : getProductTitle()}
        </ProductName>
        {location.pathname === '/login' || location.pathname === '/' ? null : (
          <ProductSelectorWrapper>
            <ProductSelector
              visible={showDropdown}
              onClose={toggleDropdown}
              maxHeight="253px"
            />
          </ProductSelectorWrapper>
        )}
      </WrapperContent>
    </Wrapper>
  );
};

export default SecondaryHeader;
