import * as React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import { PageContentRow } from '../components/layout/PageContentRow';
import TopicContent from 'src/components/TopicContent';
import Sidebar from '../components/sidebar/Sidebar';
import { getSiblings } from 'src/helpers/node';
import styled from 'styled-components';
import { useTranslation } from 'src/hooks/useTranslation';
import { useNodes } from '../hooks/useNodes';
import { useFetchBrowse } from 'src/hooks/useFetchData';
import Breadcrumbs from '../components/Breadcrumbs';

const BreadCrumbsWrapper = styled.div`
  align-self: flex-end;
  width: calc(1070 / 1440 * 100%);

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    width: calc(700 / 1024 * 100%);
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    width: 100%;
    align-self: flex-start;
  }
`;

const SidebarsWrapper = styled.div<{ hasRelatedArticles: boolean }>`
  display: contents;

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    max-width: calc(324 / 1024 * 100%);
    display: flex;
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    max-width: 100%;
    flex-direction: row;
    order: 2;
    padding: ${({ hasRelatedArticles }) => hasRelatedArticles && '0 100px'};
  }
`;

const RelatedContent = styled.div`
  flex: 1;
  width: 370px;
  max-width: calc(370 / 1440 * 100%);
  padding: ${({ theme }) => `60px ${theme.space.m} 0px ${theme.space.m}`};
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    position: initial;
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    padding: 0;
    margin-top: ${({ theme }) => theme.space.l};
    max-width: 50%;
    padding-left: 12px;
  }
`;

const Topic: React.FC = () => {
  const { t } = useTranslation();
  const relatedContentRef = React.useRef<HTMLDivElement | null>(null);
  const [hasRelatedArticles, setHasRelatedArticles] = React.useState(false);
  const { topicId, path } = useParams<{ topicId: string; path: string }>();
  const { data } = useFetchBrowse();

  const topLevel = data?.data.find(item => item.topicId === path);

  const siblings =
    getSiblings(topicId, topLevel).filter(
      sibling => sibling && sibling.topicId,
    ) ?? [];

  const { breadcrumbs } = useNodes(
    topLevel,
    topicId,
    path,
    topLevel?.title ?? '',
  );

  React.useEffect(() => {
    if (
      siblings.length > 0 &&
      relatedContentRef.current.classList.contains('has-related')
    ) {
      setHasRelatedArticles(true);
    }
  }, [siblings?.length]);

  return (
    <Page>
      <PageContentWrapper flex={1}>
        <BreadCrumbsWrapper>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </BreadCrumbsWrapper>
        <PageContentRow center={siblings.length < 1}>
          <SidebarsWrapper hasRelatedArticles={hasRelatedArticles}>
            <Sidebar
              isSameCategory
              title={t('topic.sidebar.sameCategoryTitle')}
              items={siblings}
            />
            <RelatedContent id="related" ref={relatedContentRef} />
          </SidebarsWrapper>
          <TopicContent topicId={topicId} />
        </PageContentRow>
      </PageContentWrapper>
    </Page>
  );
};

export default Topic;
