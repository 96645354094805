import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import Page from '../components/layout/Page';
import { PageContentRow } from '../components/layout/PageContentRow';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import { useFetchBrowse } from '../hooks/useFetchData';
import BrowseContent from '../components/BrowseContent';
import { useNodes } from 'src/hooks/useNodes';
import { useTranslation } from 'src/hooks/useTranslation';
import { MainContent } from '../components/layout/MainContent';

const Browse: React.FC = () => {
  const { t } = useTranslation();
  const { manifestId, childId, path } =
    useParams<{ childId: string; path: string; manifestId: string }>();

  const { isLoading, error, data } = useFetchBrowse();

  const [topLevelItem, setTopLevelItem] = React.useState<
    Quintus.Node | undefined
  >(undefined);

  React.useEffect(() => {
    if (data && data.data) {
      setTopLevelItem(data.data.find(item => item.topicId === path));
    }
  }, [data, path]);

  const { breadcrumbs } = useNodes(
    topLevelItem,
    childId,
    path,
    topLevelItem?.title ?? '',
  );

  if (data?.status === 404) {
    return <Navigate to={`/${manifestId}/404`} />;
  }

  return (
    <Page>
      <PageContentWrapper flex={1}>
        <PageContentRow center={true}>
          <MainContent>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            {!error && data?.status === 200 ? (
              <BrowseContent
                parentNode={topLevelItem}
                childId={childId}
                path={path}
                loading={isLoading}
              />
            ) : (
              <div>{t('browse.errormessage')}</div>
            )}
          </MainContent>
        </PageContentRow>
      </PageContentWrapper>
    </Page>
  );
};

export default Browse;
