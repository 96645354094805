import jwt_decode from 'jwt-decode';
import ReactGA from 'react-ga';
import { useEffect, useState, useContext } from 'react';
import { useMutation } from 'react-query';
import api, { authApi, clearTokens, getToken, setToken } from '../services/api';
import { UserContext } from 'src/context/UserContext';
import { useNavigate } from 'react-router-dom';

type ErrorResponse = {
  status: number;
};
type ConfigResponse = {
  status: number;
  data: Quintus.ConfigObject;
};

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authError, setAuthError] = useState(null);
  const { mutateAsync: authorizeAsync } = useValidate();
  const { mutateAsync: refreshAsync } = useRefreshToken();
  const { mutateAsync: storageAsync } = useStorageToken();
  const { mutateAsync: configAsync } = useSetConfig();
  const navigate = useNavigate();

  const { setHasFullAccess } = useContext(UserContext);

  useEffect(() => {
    const setData = async () => {
      try {
        const storageToken = await storageAsync();
        const config = await configAsync();

        if (storageToken.status === 200) {
          setToken('storageToken', storageToken.data.token);
        }

        if (config.status === 200 && config.data) {
          sessionStorage.setItem('config', JSON.stringify(config.data));
          ReactGA.initialize(config.data.configuration.ga_key);
        }
      } catch (error) {}
    };

    const fetch = async () => {
      try {
        let decodedAccess = null;
        let decodedRefresh = null;
        const token = getToken('token');
        const refreshToken = getToken('refreshToken');

        if (token) {
          decodedAccess = jwt_decode<Quintus.JwtToken>(token);
        }
        if (refreshToken) {
          decodedRefresh = jwt_decode<Quintus.JwtToken>(refreshToken);
        }

        // if access token valid
        if (token && Date.now() / 1000 < decodedAccess.exp) {
          setIsAuthenticated(true);

          if (refreshToken && Date.now() / 1000 < decodedRefresh.exp) {
            setHasFullAccess(true);
          }
        } else {
          if (refreshToken && Date.now() / 1000 < decodedRefresh.exp) {
            //get new access
            const refresh = await refreshAsync();
            if (refresh.status === 200) {
              setToken('token', refresh.data.token);
              await setData();
              setHasFullAccess(true);
              setIsAuthenticated(true);
            } else {
              clearTokens();
              setAuthError('Invalid token');
            }
          } else {
            clearTokens();
            if (refreshToken) {
              setAuthError('Token expired');
            }
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log('Validate error => ', error);
        setAuthError(error);
        setIsAuthenticated(false);
        setHasFullAccess(false);
        setIsLoading(false);
      }
    };
    fetch();
  }, [
    authorizeAsync,
    configAsync,
    refreshAsync,
    storageAsync,
    setHasFullAccess,
    navigate,
  ]);

  return { isAuthenticated, isLoading, authError };
}

export function useSetConfig() {
  return useMutation<ConfigResponse>(() =>
    api.get(`/auth/v1/jwt/config`, {}).then(response => {
      return { status: response.status, data: response.data };
    }),
  );
}

export function useConfig(): Quintus.ConfigObject | undefined {
  if (sessionStorage.getItem('config')) {
    return JSON.parse(sessionStorage.getItem('config'));
  }

  return undefined;
}

export function useValidate() {
  return useMutation<
    {
      status: number;
    },
    ErrorResponse,
    string
  >(token =>
    authApi
      .post(
        `/auth/v1/jwt/authorize`,
        {
          customer: process.env.REACT_APP_CUSTOMER,
          method: 'Get',
          site: process.env.REACT_APP_SITE,
        },
        {
          headers: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
            Authorization: `Bearer ${token ?? getToken('token')}`,
          },
        },
      )
      .then(response => {
        return { status: response?.status };
      })
      .catch(error => {
        return error;
      }),
  );
}

type UserObject = {
  username: string;
  password: string;
};

export function useAuthenticate() {
  return useMutation<
    { status: number; data: { token: string; refreshToken: string } },
    ErrorResponse,
    UserObject
  >(user =>
    authApi
      .post(
        `/auth/v1/jwt/authenticate`,
        {
          username: user.username,
          password: user.password,
          customer: process.env.REACT_APP_CUSTOMER,
          site: process.env.REACT_APP_SITE,
          environment: process.env.REACT_APP_ENVIRONMENT,
        },
        {
          headers: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
          },
        },
      )
      .then(response => {
        return { status: response.status, data: response.data };
      }),
  );
}

export function useStorageToken() {
  return useMutation<
    { status: number; data: { token: string } },
    ErrorResponse
  >(() => api.post(`/auth/v1/storage/token`));
}

export function useRefreshToken() {
  return useMutation<
    { status: number; data: { token: string; refreshToken: string } },
    ErrorResponse
  >(() =>
    authApi.post(
      `/auth/v1/jwt/refresh`,
      {},
      {
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
          Authorization: `Bearer ${getToken('refreshToken')}`,
        },
      },
    ),
  );
}

export async function renewAccessToken(): Promise<{
  status: number;
  data: { token: string };
}> {
  try {
    const res = await authApi.post(
      `/auth/v1/jwt/refresh`,
      {},
      {
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
          Authorization: `Bearer ${getToken('refreshToken')}`,
        },
      },
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    console.log(error);
  }
}
