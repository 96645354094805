import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import SearchResults from './SearchResults';
import useSearch from '../../hooks/useSearch';
import { useDebounce } from '../../hooks/useDebounce';
import { useListFocus } from '../../hooks/useListFocus';
import { useCloseClick } from '../../hooks/useCloseClick';
import { useTranslation } from '../../hooks/useTranslation';
import { useNavigate, useParams } from 'react-router-dom';
import { clearTokens } from 'src/services/api';

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const SearchIconWrapper = styled.button`
  position: absolute;
  right: 0;
  cursor: pointer;
  height: 100%;
  padding: 0 13px;
`;

const SearchIcon = styled.img`
  width: 18px;
  height: 18px;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    width: 17px;
    height: 17px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  animation: ${show} 0.8s;
`;

const SearchInput = styled.input<{
  hasValue: boolean;
  isSearchPage: boolean;
}>`
  padding: 11px 13px;
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  border-color: ${({ theme, isSearchPage, hasValue }) =>
    isSearchPage || hasValue ? theme.colors.quintusGray : 'transparent'};
  line-height: 14.95px;
  color: ${({ theme }) => theme.colors.quintusGray};
  font-weight: 400;
  outline: none;
  border-radius: ${({ theme }) => theme.radii.xs};
  border-width: 1px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.quintusGray};
  }
`;

const ClearIconWrapper = styled.button`
  display: flex;
  animation: ${show} 0.8s;
  position: absolute;
  right: 50px;
  cursor: pointer;
  height: 100%;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    right: 50px;
  }
`;
const ClearIcon = styled.img`
  width: 13.5px;
  height: 13.5px;
`;

type SearchBoxProps = {
  isSearchPage?: boolean;
  setSearchResults?(data: Quintus.SearchResults[]): void;
  hasScrolledToBottom?: boolean;
  filters?: Quintus.SearchFilter[];
  type: Quintus.SearchTypes;
  searchOnErrorCode?: boolean;
};

const SearchBox: React.FC<SearchBoxProps> = ({
  isSearchPage,
  setSearchResults,
  hasScrolledToBottom,
  filters,
  type,
  searchOnErrorCode,
}) => {
  const navigate = useNavigate();
  const { manifestId, searchTerm } =
    useParams<{ manifestId: string; searchTerm: string }>();
  const { t } = useTranslation();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState<string>(searchTerm ?? '');
  const debouncedValue = useDebounce<string>(value, 500);
  const [parsedFilter, setParsedFilter] = React.useState(
    `manifestId eq '${manifestId}'`,
  );

  const { isError, data, fetchNextPage, error } = useSearch(
    debouncedValue,
    parsedFilter,
    manifestId,
    type,
    searchOnErrorCode,
  );
  React.useEffect(() => {
    const parsedError = error as Error;
    if (
      parsedError?.message === 'Invalid token' ||
      parsedError?.message === 'Token expired'
    ) {
      clearTokens();
      navigate('/', { state: { message: parsedError.message } });
    } else if (error) {
      setSearchResults(null);
    }
  }, [error, navigate, setSearchResults]);

  const [currentFocus, setCurrentFocus] = useListFocus(0, wrapperRef);

  const [showResultsDropdown, setShowResultsDropdown] =
    React.useState<boolean>(false);
  const close = useCloseClick(wrapperRef, debouncedValue);
  const [renderDetailedResults, setRenderDetailedResults] = React.useState(
    debouncedValue && isSearchPage ? true : false,
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenderDetailedResults(false);
    setValue(e.target.value);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setRenderDetailedResults(true);
      navigate(`/${manifestId}/${type}/${value}`);
    }
  };

  React.useEffect(() => {
    let theFilter = `manifestId eq '${manifestId}'`;
    if (filters) {
      let baseFilter = '';
      const filterVar = type === 'alarms' ? 'alarmlevel' : 'topicType';

      filters.forEach(filter => {
        if (baseFilter) {
          baseFilter = baseFilter.concat(
            ` or ${filterVar} eq '${filter.value}'`,
          );
        } else {
          baseFilter = baseFilter.concat(
            ` and (${filterVar} eq '${filter.value}'`,
          );
        }
      });
      if (baseFilter) {
        theFilter = theFilter.concat(`${baseFilter})`);
      }
    }
    setParsedFilter(theFilter);
  }, [filters, manifestId, type]);

  React.useEffect(() => {
    if (hasScrolledToBottom) {
      fetchNextPage();
    }
  }, [hasScrolledToBottom, fetchNextPage]);

  React.useEffect(() => {
    if (
      isSearchPage &&
      data?.pages &&
      (renderDetailedResults ||
        (type === 'alarms' && debouncedValue.length === 0))
    ) {
      setSearchResults(data.pages);
    }
  }, [
    isSearchPage,
    searchTerm,
    data,
    renderDetailedResults,
    debouncedValue,
    type,
    setSearchResults,
  ]);

  React.useEffect(() => {
    if (debouncedValue.length > 2) {
      setShowResultsDropdown(true);
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    if (currentFocus === -1) {
      inputRef.current?.focus();
    }
  }, [currentFocus]);

  React.useEffect(() => {
    if (debouncedValue.length > 2) {
      setShowResultsDropdown(true);
    }
    if (close) {
      setShowResultsDropdown(false);
    }
  }, [debouncedValue, close]);

  if (!isError) {
    // Handle error
  }

  return (
    <Wrapper ref={wrapperRef}>
      <SearchInput
        isSearchPage={isSearchPage}
        type="text"
        placeholder={t('searchbox.watermark')}
        value={value}
        hasValue={debouncedValue.length > 0}
        onChange={handleChange}
        onKeyUp={handleKeypress}
        ref={inputRef}
      />
      {debouncedValue.length > 0 && (
        <ClearIconWrapper
          onMouseDown={() => {
            setValue('');
            setShowResultsDropdown(false);
            isSearchPage && navigate(`/${manifestId}/${type}`);
          }}
        >
          <ClearIcon src="/images/clear-icon.svg" />
        </ClearIconWrapper>
      )}
      <SearchIconWrapper>
        <SearchIcon
          src="/images/search-icon.svg"
          onClick={() => {
            setRenderDetailedResults(true);
            navigate(`/${manifestId}/${type}/${debouncedValue}`);
          }}
        />
      </SearchIconWrapper>
      <SearchResults
        data={data?.pages[0]?.data ?? []}
        showResultsDropdown={showResultsDropdown && !isSearchPage}
        currentFocus={currentFocus}
        setCurrentFocus={setCurrentFocus}
      />
    </Wrapper>
  );
};

export default SearchBox;
