import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import Spinner from 'react-spinner-material';

const LoadingWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const StyledParagraph = styled.p`
  margin-top: ${({ theme }) => theme.space.s};
  font-size: ${({ theme }) => theme.fontSizes.l};
`;

const AuthTokenLoader: React.FC = () => {
  const theme = useTheme();
  return (
    <LoadingWrapper>
      <Spinner
        radius={102}
        color={theme.colors.quintusDarkBlue}
        stroke={8}
        visible={true}
      />

      <StyledParagraph>Authenticating...</StyledParagraph>
    </LoadingWrapper>
  );
};

export default AuthTokenLoader;
