import styled from 'styled-components';

export default styled.h1`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.xxl};
  line-height: ${({ theme }) => theme.lineHeights.l};
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  letter-spacing: 0.05em;
`;
