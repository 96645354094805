import * as React from 'react';
import styled from 'styled-components';
import Breadcrumbs from '../Breadcrumbs';
import H1 from '../typography/H1';
import SearchBox from './SearchBox';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Content = styled.div`
  width: calc(700 / 1440 * 100%);
  margin-left: calc(370 / 1440 * 100%);

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    margin-left: 0;
    width: 100%;
  }
`;

const StyledH1 = styled(H1)`
  margin-bottom: ${({ theme }) => theme.space.xxl};
`;

const SearchBoxWrapper = styled.div`
  width: 287px;
`;
const SearchWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.space.l};
  @media (max-width: ${({ theme }) => theme.screens.md}) {
    margin-bottom: 0;
  }
`;
const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 14px;
`;
const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
`;
const StyledLabel = styled.label`
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.quintusDarkBlue};
  font-size: 11px;
`;

const StyledInput = styled.input`
  accent-color: ${({ theme }) => theme.colors.quintusDarkBlue};
`;

const StyledParagraph = styled.p`
  font-weight: bold;
  margin-right: 9px;
  font-size: 14px;
`;

type SearchPageHeaderProps = {
  breadCrumb: Quintus.BreadCrumb;
  title: string;
  updateSearchResults(data: Quintus.SearchResults[]): void;
  hasScrolledToBottom?: boolean;
  filters?: Quintus.SearchFilter[];
  type: Quintus.SearchTypes;
};

const SearchPageHeader: React.FC<SearchPageHeaderProps> = ({
  breadCrumb,
  title,
  updateSearchResults,
  hasScrolledToBottom,
  filters,
  type,
}) => {
  const [isErrorSearch, setIsErrorSearch] = React.useState(true);
  return (
    <Wrapper>
      <Content>
        <Breadcrumbs breadcrumbs={[breadCrumb]} />
        <StyledH1>{title}</StyledH1>
        <SearchWrapper>
          <SearchBoxWrapper>
            <SearchBox
              isSearchPage={true}
              setSearchResults={updateSearchResults}
              hasScrolledToBottom={hasScrolledToBottom}
              filters={filters}
              type={type}
              searchOnErrorCode={isErrorSearch}
            />
          </SearchBoxWrapper>
          {type === 'alarms' ? (
            <RadioGroupWrapper>
              <StyledParagraph>Search by:</StyledParagraph>
              <RadioWrapper onClick={() => setIsErrorSearch(true)}>
                <StyledInput
                  type="radio"
                  checked={isErrorSearch}
                  onChange={() => setIsErrorSearch(true)}
                />
                <StyledLabel>ERROR CODE</StyledLabel>
              </RadioWrapper>
              <RadioWrapper onClick={() => setIsErrorSearch(false)}>
                <StyledInput
                  type="radio"
                  checked={!isErrorSearch}
                  onChange={() => setIsErrorSearch(false)}
                />
                <StyledLabel>FULL TEXT</StyledLabel>
              </RadioWrapper>
            </RadioGroupWrapper>
          ) : null}
        </SearchWrapper>
      </Content>
    </Wrapper>
  );
};

export default SearchPageHeader;
