import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import SearchResultItem from './SearchResultItem';

const ResultsDropdown = styled.ul`
  background-color: ${({ theme }) => theme.colors.quintusGray20};
  list-style: none;
  margin: 0;
  padding: 0 13px;

  top: 38px;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.quintusGray};
  border-top-color: transparent;
`;

const Inner = styled.div`
  max-height: 245px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 11px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.quintusListBorder};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.quintusGray};
  }
`;

type SearchResultsProps = {
  data: Quintus.SearchResult[];
  showResultsDropdown: boolean;
  currentFocus: number;
  setCurrentFocus: Dispatch<SetStateAction<number>>;
  inHeader?: boolean;
};

const SearchResults: React.FC<SearchResultsProps> = ({
  data,
  showResultsDropdown,
  currentFocus,
  setCurrentFocus,
  inHeader,
}) => (
  <>
    {showResultsDropdown && data.length > 0 && (
      <ResultsDropdown>
        <Inner>
          {data.map((result, index) => {
            return (
              <SearchResultItem
                key={index}
                data={result.document}
                focus={currentFocus === index}
                setFocus={setCurrentFocus}
                index={index}
                inHeader={inHeader}
              />
            );
          })}
        </Inner>
      </ResultsDropdown>
    )}
  </>
);

export default SearchResults;
