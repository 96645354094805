import * as React from 'react';
import styled from 'styled-components';
import { SidebarWrapper } from '../layout/SidebarWrapper';
import { useLinkFromId } from 'src/helpers/link';
import { useFetchBrowse } from '../../hooks/useFetchData';
import { Link, useParams } from 'react-router-dom';

const SidebarTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.s};
  color: ${({ theme }) => theme.colors.quintusGray};
  margin-bottom: ${({ theme }) => theme.space.l};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  overflow-wrap: break-word;
`;

const List = styled.ul`
  padding: ${({ theme }) => `0 ${theme.space.l} 0 0`};
`;

const ListItem = styled.li`
  padding: ${({ theme }) => theme.space.xs} 0;

  &:first-child {
    padding-top: 0;
  }
`;

const ListLink = styled(Link)<{ $isCurrentTopic?: boolean }>`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.lineHeights.s};
  color: ${({ theme }) => theme.colors.quintusDarkBlue};
  font-weight: 700;
  display: flex;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.quintusGreen};
  }
  color: ${({ $isCurrentTopic, theme }) =>
    $isCurrentTopic && theme.colors.quintusBlack};
`;

type SidebarProps = {
  title: string;
  items: Quintus.Node[];
  ignoreWrapperStyles?: boolean;
  isSameCategory?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
  title,
  items,
  ignoreWrapperStyles,
  isSameCategory,
}) => {
  if (items.length < 1) {
    return null;
  }

  return (
    <SidebarWrapper
      ignoreWrapperStyles={ignoreWrapperStyles}
      withWrapper={true}
    >
      {items.length > (isSameCategory ? 1 : 0) && (
        <>
          <SidebarTitle>{title}</SidebarTitle>
          <List>
            {items.map((item, index) => (
              <ListItem key={index}>
                <Item item={item} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </SidebarWrapper>
  );
};

const Item: React.FC<{ item: Quintus.Node }> = ({ item }) => {
  const { data } = useFetchBrowse();
  const { topicId } = useParams<{ topicId: string }>();
  return (
    <ListLink
      $isCurrentTopic={item.topicId === topicId}
      to={useLinkFromId(data?.data, item.topicId)}
    >
      {item.title}
    </ListLink>
  );
};

export default Sidebar;
