import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const GridTableStyle = css``;

const StandardTableStyles = css``;

const TableWrapper = styled.table<{ grid: boolean; standard: boolean }>`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: ${({ theme }) => theme.space.s};
  text-align: left;

  caption {
    font-style: italic;
    color: ${({ theme }) => theme.colors.quintusMediumGrey};
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.m};
    line-height: ${({ theme }) => theme.lineHeights.s};
  }

  th {
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSizes.ml};
    padding: ${({ theme }) => theme.space.table};
    line-height: ${({ theme }) => theme.lineHeights.s};
    border-bottom-width: ${({ theme }) => theme.borderWidths.l}px;
    border-bottom-color: ${({ theme }) => theme.colors.quintusTrueBlack};
    border-bottom-style: solid;
  }

  td {
    padding: ${({ theme }) => theme.space.table};
    line-height: ${({ theme }) => theme.lineHeights.s};
  }

  tr:not(:last-of-type) td {
    border-bottom-width: ${({ theme }) => theme.borderWidths.s}px;
    border-bottom-color: ${({ theme }) => theme.colors.quintusTableBorder};
    border-bottom-stylr: solid;
  }

  ${({ grid }) => grid && GridTableStyle};
  ${({ standard }) => standard && StandardTableStyles};
`;

type TableProps = {
  node: Quintus.Topic;
  children: React.ReactNode;
};

const Table: FC<TableProps> = ({ node, children }) => {
  const { attribs } = node;

  return (
    <>
      <TableWrapper
        className={attribs.class}
        grid={attribs.class === 'grid-table'}
        standard={attribs.class === 'standard-table'}
        id={attribs.id}
        onClick={() => console.log('click!')}
      >
        {children}
      </TableWrapper>
    </>
  );
};

export default Table;
