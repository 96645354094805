import * as React from 'react';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import { PageContent } from '../components/layout/PageContent';
import { useFetchIndexFiles, useFetchBrowse } from 'src/hooks/useFetchData';
import Breadcrumbs from '../components/Breadcrumbs';
import { useTranslation } from '../hooks/useTranslation';
import H1 from '../components/typography/H1';
import H2 from '../components/typography/H2';
import styled from 'styled-components';
import { PageContentRow } from 'src/components/layout/PageContentRow';
import { useInternalLink } from '../hooks/useInternalLink';
import { getCurrentBrowseItem } from '../helpers/node';
import { Link } from 'react-router-dom';

const IndexMenuItemWrapper = styled.li`
  padding: ${({ theme }) => `${theme.space.xs} 0`};

  &:first-child {
    padding-top: 0;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    padding: ${({ theme }) => `0 ${theme.space.s}`};
    padding-left: 0;
  }
`;

const IndexMenuItermLink = styled.a<{ disabled: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 20px;
  letter-spacing: 0.04em;
  font-weight: 700;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.quintusDisabled : theme.colors.quintusDarkBlue};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

type IndexMenuProps = {
  letter: string;
  count: number;
};

const IndexMenuItem: React.FC<IndexMenuProps> = ({ letter, count }) => (
  <IndexMenuItemWrapper>
    <IndexMenuItermLink href={`#${letter}`} disabled={count < 1}>
      {letter}
    </IndexMenuItermLink>
  </IndexMenuItemWrapper>
);

const ListItemWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 6px 0;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.quintusListBorder};
  padding-bottom: 7px;

  &:nth-child(2) {
    margin-top: 0;
  }
`;

const ListItemTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  line-height: 14.95px;
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  font-weight: 700;
  text-transform: capitalize;
`;

const ArrowIcon = styled.img``;

const ListItem: React.FC<{
  item: Quintus.IndexItem;
  browseList: Quintus.Node[];
}> = ({ item, browseList }) => {
  const { internalLink } = useInternalLink();
  const topicId = item.id.slice(0, item.id.indexOf('.')) ?? undefined;
  const [href, setHref] = React.useState<string>('');

  React.useEffect(() => {
    if (browseList) {
      const { topLevelItem, currentBrowseItem } = getCurrentBrowseItem(
        browseList,
        topicId,
      );
      if (currentBrowseItem && topLevelItem) {
        const hasChildren =
          currentBrowseItem?.children && currentBrowseItem.children.length > 0;

        setHref(
          hasChildren
            ? `/browse/${topLevelItem.topicId}/${topicId}`
            : `/${topLevelItem.topicId}/topics/${topicId}`,
        );
      } else {
        setHref(`/topics/${topicId}`);
      }
    }
  }, [browseList, topicId]);

  return (
    <ListItemWrapper to={internalLink(href)}>
      <ListItemTitle>{item.title.toLocaleLowerCase()}</ListItemTitle>
      <ArrowIcon src="/images/arrow.svg" />
    </ListItemWrapper>
  );
};

const LetterListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space.l};
`;

const StyledH2 = styled(H2)`
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: 27.6px;
  letter-spacing: 0.04em;
  border-bottom-width: 3px;
  border-bottom-color: ${({ theme }) => theme.colors.quintusDarkBlue};
  margin-bottom: ${({ theme }) => theme.space.s};
`;

type LetterListProps = {
  item: Quintus.Index;
  browseList: Quintus.Node[];
};

const LetterList: React.FC<LetterListProps> = ({ item, browseList }) => {
  if (item.items.length < 1) {
    return null;
  }

  return (
    <LetterListWrapper id={item.title}>
      <div
        style={{
          display: 'inline-flex',
        }}
      >
        <StyledH2>{item.title}</StyledH2>
      </div>
      {item.items.map((letterItem, index) => (
        <ListItem item={letterItem} key={index} browseList={browseList} />
      ))}
    </LetterListWrapper>
  );
};

const LetterListsWrapper = styled.div`
  max-height: 100vh;
  overflow-y: scroll;
  padding-right: ${({ theme }) => theme.space.xxxl};

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.quintusListBorder};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.quintusGray};
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    order: 2;
    margin-top: ${({ theme }) => theme.space.l};
  }
`;

const IndexMenu = styled.ul`
  padding-left: 89px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    display: flex;
    order: 1;
    padding-left: 0;
    /* position: sticky; */
  }
`;

const StyledH1 = styled(H1)`
  margin: ${({ theme }) => theme.space.xxl} 0;
`;

const IndexList: React.FC = () => {
  const { t } = useTranslation();
  const { error, data } = useFetchIndexFiles();
  const { data: browseData } = useFetchBrowse();

  return (
    <Page>
      <PageContentWrapper flex={1}>
        {error || !data ? null : (
          <PageContent width="1070px" alignMent="flex-end">
            <Breadcrumbs
              breadcrumbs={[{ label: t('index.title'), url: 'index' }]}
            />
            <StyledH1>{t('index.title')}</StyledH1>
            <PageContentRow>
              <LetterListsWrapper>
                {data?.data.map((item, index) => (
                  <LetterList
                    key={index}
                    item={item}
                    browseList={browseData?.data}
                  />
                ))}
              </LetterListsWrapper>
              <IndexMenu>
                {data?.data.map((item, index) => (
                  <IndexMenuItem
                    letter={item.title}
                    count={item.items.length}
                    key={index}
                  />
                ))}
              </IndexMenu>
            </PageContentRow>
          </PageContent>
        )}
      </PageContentWrapper>
    </Page>
  );
};

export default IndexList;
