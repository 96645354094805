export function storeProduct(p: string) {
  sessionStorage.setItem('product', p);
}

export function getProduct() {
  return sessionStorage.getItem('product');
}

export function getProductTitle() {
  return sessionStorage.getItem('productTitle');
}

export function storeProductTitle(t: string) {
  sessionStorage.setItem('productTitle', t);
}
