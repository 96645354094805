import * as React from 'react';
import styled from 'styled-components';
import Portal from '../Portal';

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const ModalWindow = styled.div<{ width?: string }>`
  max-width: ${({ width }) => (width ? width : '840px')};
  width: 80%;
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 1px 2px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;

interface Props {
  isVisible: boolean;
  width?: string;
  onClose: () => void;
}

const Modal: React.FC<Props> = ({ children, isVisible, width, onClose }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Portal id="#modal">
      <Overlay onClick={onClose}>
        <ModalWindow width={width} onClick={e => e.stopPropagation()}>
          <>
            <ModalHeader>
              <CloseIcon src="/images/close-blue.svg" onClick={onClose} />
            </ModalHeader>
            {children}
          </>
        </ModalWindow>
      </Overlay>
    </Portal>
  );
};

export default Modal;
