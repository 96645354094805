import * as React from 'react';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import { useTranslation } from 'src/hooks/useTranslation';
import SearchPageMain from '../components/search/SearchPageMain';

const Alarms: React.FC = () => {
  const { t } = useTranslation();

  const filters: Quintus.AlarmFilter[] = [
    {
      label: t('alarmFilter.warning'),
      value: 'Warning',
    },
    {
      label: t('alarmFilter.alarm'),
      value: 'Alarm',
    },
    {
      label: t('alarmFilter.information'),
      value: 'Information',
    },
  ];

  return (
    <Page>
      <PageContentWrapper flex={1}>
        <SearchPageMain
          breadCrumb={{ label: t('alarms.alarmLabel'), url: '/alarms' }}
          title={t('alarms.title')}
          type="alarms"
          filters={filters}
        />
      </PageContentWrapper>
    </Page>
  );
};

export default Alarms;
