import * as React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Home from './pages/Home';
import { ThemeProvider } from 'styled-components';
import theme from './config/theme';
import Topic from './pages/Topic';
import Browse from './pages/Browse';
import Search from './pages/Search';
import Alarms from './pages/Alarms';
import { useAuth, useConfig } from './hooks/useAuth';
import Hotspots from './pages/Hotspots';
import 'swiper/swiper-bundle.min.css';
import IndexList from './pages/IndexList';
import Video from './pages/Video';
import Login from './pages/Login';
import { getProduct } from './helpers/products';
import ProductWizard from './pages/ProductWizard';
import { LanguageContextProvider } from './context/LanguageContext';
import { UserContextProvider } from './context/UserContext';
import { clearTokens } from './services/api';
import ReactGA from 'react-ga';

function App() {
  const config = useConfig();
  React.useEffect(() => {
    if (config?.configuration?.ga_key) {
      ReactGA.initialize(config.configuration.ga_key);
    }
  }, [config]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <UserContextProvider>
          <LanguageContextProvider>
            <Routes>
              <Route element={<PublicRoutes />}>
                <Route path={'/login'} element={<Login />} />
              </Route>
              <Route element={<WizardRoutes />}>
                <Route path="/" element={<ProductWizard />} />
              </Route>
              <Route path=":manifestId/" element={<ProductRoutes />}>
                <Route path="" element={<Home />} />
                <Route path="index" element={<IndexList />} />
                <Route path="video" element={<Video />} />
                <Route path="alarms" element={<Alarms />} />
                <Route path="alarms/:searchTerm" element={<Alarms />} />
                <Route path="search" element={<Search />} />
                <Route path="search/:searchTerm" element={<Search />} />
                <Route path="hotspot/:hotspotId" element={<Hotspots />} />
                <Route path="topics/:topicId" element={<Topic />} />
                <Route path=":path/topics/:topicId" element={<Topic />} />
                <Route path="browse/:path" element={<Browse />} />
                <Route path="browse/:path/:childId" element={<Browse />} />
                <Route path="logout" element={<Logout />} />
              </Route>
            </Routes>
            <div id="modal"></div>
          </LanguageContextProvider>
        </UserContextProvider>
      </Router>
    </ThemeProvider>
  );
}

function PublicRoutes() {
  const [searchParams] = useSearchParams();
  const { isAuthenticated, isLoading, authError } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return null;
  }
  if (isAuthenticated && !searchParams.has('token')) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <Outlet />;
}

function WizardRoutes() {
  const [searchParams] = useSearchParams();
  const { isAuthenticated, isLoading, authError } = useAuth();
  const location = useLocation();

  if (authError) {
    return <Navigate to={`/login`} state={{ message: authError }} replace />;
  }
  if (isLoading) {
    return null;
  }
  if (!isAuthenticated || searchParams.has('token')) {
    return (
      <Navigate
        to={`/login${location.search}`}
        state={{ from: location }}
        replace
      />
    );
  }
  return <Outlet />;
}

function ProductRoutes() {
  const [searchParams] = useSearchParams();
  const { isAuthenticated, isLoading, authError } = useAuth();
  const location = useLocation();
  const product = getProduct();
  const { manifestId } = useParams<{ manifestId: string }>();

  if (authError) {
    return <Navigate to={`/login`} state={{ message: authError }} replace />;
  }
  if (isLoading) {
    return null;
  }
  if (!isAuthenticated || searchParams.has('token')) {
    return (
      <Navigate
        to={`/login${location.search}`}
        state={{ from: location }}
        replace
      />
    );
  }
  if (!product || product !== manifestId) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <Outlet />;
}

const Logout: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    clearTokens();
    navigate('/');
  }, [navigate]);

  return null;
};

export default App;
