import * as React from 'react';
import { MainContent } from '../layout/MainContent';
import Filters from '../Filters';
import ResultsCount from './ResultsCount';
import { PageContentRow } from '../layout/PageContentRow';
import SearchPageHeader from './SearchPageHeader';
import AlarmItem from '../AlarmItem';
import SearchResultListItem from '../SearchResultListItem';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

type SearchPageMainProps = {
  filters: Quintus.SearchFilter[];
  title: string;
  breadCrumb: Quintus.BreadCrumb;
  type: Quintus.SearchTypes;
  updateHasResults?(value: boolean): void;
  noActiveSearchComponent?: React.ReactNode;
};
const ResultsWrapper = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
  padding: ${({ theme }) =>
    `${theme.space.xs} ${theme.space.md} ${theme.space.xs} ${theme.space.xs}`};

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.quintusListBorder};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.quintusGray};
  }
`;

const StyledMainContent = styled(MainContent)`
  width: calc(700 / 1340 * 100%);

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    width: calc(700 / 1024 * 100%);
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    width: 100%;
  }
`;

const SearchPageMain: React.FC<SearchPageMainProps> = ({
  filters,
  title,
  breadCrumb,
  type,
  noActiveSearchComponent,
}) => {
  const { searchTerm } = useParams<{ searchTerm: string }>();
  const [activeFilters, setActiveFilters] = React.useState<
    Quintus.SearchFilter[]
  >([]);
  const scrollRef = React.useRef(null);
  const [searchResults, setSearchResults] =
    React.useState<Quintus.SearchResults[]>(null);

  const updateActiveFilters = React.useCallback(
    (filters: Quintus.SearchFilter[]) => {
      setActiveFilters(filters);
    },
    [],
  );

  const updateSearchResults = React.useCallback(
    (data: Quintus.SearchResults[]) => {
      setSearchResults(data);
    },
    [],
  );

  const { ref: viewRef, inView } = useInView();

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [searchTerm]);

  return (
    <>
      <SearchPageHeader
        breadCrumb={breadCrumb}
        title={title}
        type={type}
        hasScrolledToBottom={inView && scrollRef.current.scrollTop !== 0}
        filters={activeFilters}
        updateSearchResults={updateSearchResults}
      />
      <PageContentRow>
        <Filters
          activeFilters={activeFilters}
          updateActiveFilters={updateActiveFilters}
          showFilters={type === 'alarms' ? true : searchResults?.length > 0}
          filters={filters}
        />
        <MainContent>
          {searchTerm || type === 'alarms' ? (
            <>
              <ResultsCount
                count={
                  searchResults?.length > 0 && searchResults[0]?.count > 0
                    ? searchResults[0]?.count
                    : 0
                }
              />
              <ResultsWrapper ref={scrollRef}>
                {searchResults?.length > 0 &&
                  searchResults.map((page, pageIndex: number) =>
                    page.data.map((item, index) => {
                      if (
                        pageIndex === searchResults.length - 1 &&
                        index === page.data.length - 1
                      ) {
                        return (
                          <div ref={viewRef} key={item.document.topicId}>
                            {type === 'alarms' ? (
                              <AlarmItem item={item} />
                            ) : (
                              <SearchResultListItem item={item.document} />
                            )}
                          </div>
                        );
                      }
                      if (type === 'alarms') {
                        return (
                          <AlarmItem item={item} key={item.document.topicId} />
                        );
                      } else {
                        return (
                          <SearchResultListItem
                            item={item.document}
                            key={item.document.topicId}
                          />
                        );
                      }
                    }),
                  )}
              </ResultsWrapper>
            </>
          ) : (
            noActiveSearchComponent && noActiveSearchComponent
          )}
        </MainContent>
      </PageContentRow>
    </>
  );
};

export default SearchPageMain;
