import React, { useCallback, useContext, useEffect, useRef } from 'react';
import {
  useAuthenticate,
  useStorageToken,
  useSetConfig,
  useValidate,
} from 'src/hooks/useAuth';
import { getToken, setToken } from 'src/services/api';
import styled, { keyframes } from 'styled-components';
import Page from 'src/components/layout/Page';
import PageContentWrapper from 'src/components/layout/PageContentWrapper';
import { useTranslation } from 'src/hooks/useTranslation';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import { UserContext } from 'src/context/UserContext';
import ErrorModal from 'src/components/modal/ErrorModal';
import AuthTokenLoader from 'src/components/AuthTokenLoader';

const LoginWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: url('/images/loginbackground.svg') no-repeat;
  background-size: cover;
  justify-content: center;
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  max-width: 100%;
  width: 604px;
  background-color: ${({ theme }) => theme.colors.quintusDarkBlue};
  margin-left: 150px;

  @media (max-width: 1200px) {
    margin: 0 auto;
  }
`;

const LoginBoxTitle = styled.h1`
  color: ${({ theme }) => theme.colors.quintusWhite};
  margin-bottom: ${({ theme }) => theme.space.xxxl};
  line-height: 43.7px;
  font-size: 38px;
  font-weight: 400;
  text-transform: uppercase;
`;

const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  width: 100%;
  display: flex;
  height: 48px;
  padding-left: 48px;
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.xxs};
  font-family: Arial, Helvetica, sans-serif;
  color: ${({ theme }) => theme.colors.quintusGray};
  font-weight: 400;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.quintusGray};
  }
`;
const StyledP = styled.p`
  color: ${({ theme }) => theme.colors.quintusWhite};
  font-size: 20px;
  line-height: 23px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const StyledButton = styled.button`
  width: 192px;
  height: 52px;
  border: 1px solid ${({ theme }) => theme.colors.quintusWhite};
  border-radius: 30px;
  align-self: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.quintusWhite};
  margin-top: 31px;
`;

const InputWrapper = styled.div<{ icon: string; height: string }>`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  margin-bottom: ${({ theme }) => theme.space.m};

  &:before {
    content: ' ';
    display: block;
    width: 22px;
    height: ${({ height }) => height};
    position: absolute;
    margin-left: 14px;
    background-repeat: no-repeat;
    background-image: ${({ icon }) => `url(/images/${icon}.svg)`};
  }
`;

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const LoginError = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.quintusWhite};
  animation: ${show} 0.8s;
`;

const Login: React.FC = () => {
  const [initializing, setInitializing] = React.useState(true);
  const usernameRef = useRef(undefined);
  const passwordRef = useRef(undefined);
  const { t } = useTranslation(true);
  const [loginError, setLoginError] = React.useState(false);
  const [tokenError, setTokenError] = React.useState(null);

  const { mutate } = useAuthenticate();
  const { mutateAsync: authorizeAsync } = useValidate();
  const { mutateAsync: storageAsync } = useStorageToken();
  const { mutateAsync: configAsync } = useSetConfig();
  const { setHasFullAccess } = useContext(UserContext);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { state } = useLocation() as any;

  const from = state?.from?.pathname || '/';

  const setData = useCallback(async () => {
    try {
      const storageToken = await storageAsync();
      const config = await configAsync();

      if (storageToken.status === 200) {
        setToken('storageToken', storageToken.data.token);
      }

      if (config.status === 200 && config.data) {
        sessionStorage.setItem('config', JSON.stringify(config.data));
        ReactGA.initialize(config.data.configuration.ga_key);
      }
    } catch (error) {}
  }, [storageAsync, configAsync]);

  useEffect(() => {
    if (state?.from?.state?.message === 'Invalid token') {
      setTokenError('Token expired. Please log in again');
    } else if (state?.from?.state?.message === 'Token expired') {
      setTokenError(
        'Token expired. Please login through the Quintus portal again',
      );
    }
  }, [state]);

  useEffect(() => {
    if (searchParams) {
      if (!searchParams.has('token')) {
        setInitializing(false);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const authorizeToken = async (theToken: string) => {
      try {
        const validated = await authorizeAsync(theToken);

        if (validated?.status === 200) {
          setToken('token', theToken);
          sessionStorage.removeItem('refreshToken');
          ['refreshToken', 'product', 'productTitle'].forEach(token =>
            sessionStorage.removeItem(token),
          );
          await setData();
          setHasFullAccess(false);
          navigate(from, { replace: true });
        } else {
          [
            'token',
            'refreshToken',
            'storageToken',
            'config',
            'product',
            'productTitle',
          ].forEach(token => sessionStorage.removeItem(token));
          searchParams.delete('token');
          setSearchParams(searchParams);
          setLoginError(true);
        }
        setInitializing(false);
      } catch (error) {
        console.log('Validate error => ', error);
        if (theToken) {
          ['token', 'refreshToken', 'storageToken', 'config'].forEach(token =>
            sessionStorage.removeItem(token),
          );
          searchParams.delete('token');
          setSearchParams(searchParams);
        }
        setLoginError(true);
        setInitializing(false);
      }
    };
    if (searchParams.has('token')) {
      const loginToken = searchParams.get('token');
      authorizeToken(loginToken);
    }
  }, [
    searchParams,
    setSearchParams,
    from,
    navigate,
    authorizeAsync,
    setData,
    setHasFullAccess,
  ]);

  const handleSubmit = e => {
    e.preventDefault();

    mutate(
      {
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      },
      {
        onSuccess: async data => {
          if (data.status === 401) {
            setLoginError(true);
          }

          if (data.status === 200 && data.data.token) {
            setToken('token', data.data.token);
            setToken('refreshToken', data.data.refreshToken);

            await setData();
            navigate(from, { replace: true });
          }
        },
      },
    );
  };

  return (
    <Page hideMenu={true} hideFooter={true} initializing={initializing}>
      {initializing ? (
        <PageContentWrapper flex={1} centerContent>
          <AuthTokenLoader />
        </PageContentWrapper>
      ) : (
        <LoginWrapper>
          <PageContentWrapper withoutPadding={true} withoutBgColor={true}>
            <LoginBox>
              <LoginBoxTitle>
                {t('login.title') ??
                  'Quintus Service and Operating Information '}
              </LoginBoxTitle>
              <StyledP>{t('login.sign_in') ?? 'Sign in'}</StyledP>
              <InputContainer onSubmit={handleSubmit}>
                <InputWrapper icon="email-action-unread" height="16px">
                  <StyledInput
                    ref={usernameRef}
                    type="text"
                    placeholder="Username"
                  />
                </InputWrapper>
                <InputWrapper icon="lock" height="22px">
                  <StyledInput
                    ref={passwordRef}
                    type="password"
                    placeholder="Password"
                  />
                </InputWrapper>
                {loginError && (
                  <LoginError>
                    {t('login.error') ?? 'Wrong user credentials'}
                  </LoginError>
                )}
                <StyledButton type="submit">
                  {t('login.login') ?? 'Log in'}
                </StyledButton>
              </InputContainer>
            </LoginBox>
          </PageContentWrapper>
        </LoginWrapper>
      )}
      <ErrorModal
        errorMessage={tokenError}
        closeHandler={() => setTokenError(null)}
      />
    </Page>
  );
};

export default Login;
