import * as React from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import { getToken } from 'src/services/api';
import { useConfig } from 'src/hooks/useAuth';

const StyledVideo = styled.video`
  width: 100%;
  margin-top: ${({ theme }) => theme.space.l};
`;

type VideoModalProps = {
  isVisible: boolean;
  posterId: string;
  filename: string;
  closeHandler: () => void;
};

const VideoModal: React.FC<VideoModalProps> = ({
  isVisible,
  posterId,
  filename,
  closeHandler,
}) => {
  const config = useConfig();
  return (
    <Modal isVisible={isVisible} onClose={closeHandler} width="1024px">
      <StyledVideo
        controls
        poster={`${config?.configuration.storage_url}${posterId}.jpg?${getToken(
          'storageToken',
        )}`}
        src={`${config?.configuration.storage_url}${filename}?${getToken(
          'storageToken',
        )}`}
      ></StyledVideo>
    </Modal>
  );
};

export default VideoModal;
