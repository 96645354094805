import * as React from 'react';
import Portal from './Portal';
import Sidebar from './sidebar/Sidebar';
import { useTranslation } from '../hooks/useTranslation';

type RelatedContentProps = {
  node: Quintus.Topic;
};

const RelatedContent: React.FC<RelatedContentProps> = ({ node }) => {
  const { t } = useTranslation();
  const items: Quintus.Node[] = [];
  const relations = node.children.find(
    child => child.attribs && child.attribs.class.includes('relations'),
  );

  if (relations) {
    relations.children.map(child => {
      if (child.attribs && child.attribs.class.includes('rel')) {
        items.push({
          topicId: child.attribs.href ?? '',
          title: child.children[0].data ?? '',
          overview: '',
        });
      }
    });
  }

  if (items.length < 1 || node.parent.attribs['data-template'] === '1080') {
    return null;
  }

  return (
    <Portal id="#related" className={items.length > 1 && 'has-related'}>
      <Sidebar
        title={t('topic.sidebar.relatedArticlesTitle')}
        items={items}
        ignoreWrapperStyles={true}
      />
    </Portal>
  );
};

export default RelatedContent;
