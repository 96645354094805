import React, { useCallback, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import LanguageDropdown from 'src/components/LanguageDropdown';
import { storeProduct, storeProductTitle } from 'src/helpers/products';
import {
  useFetchLanguagesLOCAL,
  useProductConfigs,
} from 'src/hooks/useFetchData';

export interface LanguageContextInterface {
  languages: Quintus.LanguageItem[];
  productLanguages: Quintus.LanguageItem[];
  currentLanguage: Quintus.LanguageItem;
  updateLanguage: (languageCode: string) => string;
}

const LanguageContext = React.createContext<LanguageContextInterface>({
  languages: [],
  productLanguages: [],
  currentLanguage: null,
  updateLanguage: () => '',
});

const LanguageContextProvider: React.FC = ({ children }) => {
  const { error: langError, data: languages } = useFetchLanguagesLOCAL();
  const { error: productError, data: products } = useProductConfigs();

  const [productLanguages, setProductLanguages] = React.useState<
    Quintus.LanguageItem[]
  >([]);
  const [currentLanguage, setCurrentLanguage] =
    React.useState<Quintus.LanguageItem>(null);

  const [currentProduct, setCurrentProduct] = React.useState('');
  const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  const match = useMatch('/:manifestId/*');

  const updateLanguage = useCallback(
    (languageCode: string): string => {
      const validLang = languages.find(
        langItem => langItem.code === languageCode,
      );
      if (validLang) {
        setCurrentLanguage(validLang);

        if (currentProduct) {
          const found = products.find(
            product =>
              product.Lang === validLang.code &&
              currentProduct.includes(product.KtyValidity.toString()),
          );
          if (found) {
            storeProduct(found.ManifestId);
            storeProductTitle(found.Description);
            return found.ManifestId;

            //if current product does not support new language
          } else {
            ['product', 'productTitle'].forEach(token =>
              sessionStorage.removeItem(token),
            );
          }
        }
        return null;
      }
    },
    [languages, products, currentProduct],
  );

  //get current product from url
  useEffect(() => {
    if (match && match.params.manifestId !== 'login') {
      //if we have a product
      setCurrentProduct(match.params.manifestId);
    } else {
      setCurrentProduct(null);
    }
  }, [match]);

  //set the language according the chosen product
  useEffect(() => {
    if (languages) {
      if (currentProduct) {
        const prodArray = currentProduct.split('_');
        const langCode = prodArray[1];
        const validLang = languages.find(
          langItem => langItem.code === langCode,
        );
        if (validLang) {
          setCurrentLanguage(validLang);
        }
      } else {
        setCurrentLanguage(languages[0]);
      }
    }
  }, [currentProduct, languages]);

  //set available languages for the current product
  useEffect(() => {
    if (languages && products && currentProduct) {
      //get all products with same validity
      const languageProducts = products.filter(product =>
        currentProduct.includes(product.KtyValidity.toString()),
      );
      //extract languages from available products
      const langs = languages.filter(lang =>
        languageProducts.find(lp => lp.Lang === lang.code),
      );
      setProductLanguages(langs);
    }
  }, [languages, products, currentProduct]);

  const value = React.useMemo(
    () => ({ languages, productLanguages, currentLanguage, updateLanguage }),
    [languages, productLanguages, currentLanguage, updateLanguage],
  );

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <LanguageContext.Provider value={value}>
      <LanguageDropdown
        isVisible={isDropdownVisible}
        toggleDropdown={toggleDropdown}
      />
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContextProvider, LanguageContext };
