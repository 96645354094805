import styled, { css } from 'styled-components';

const WrapperStyles = css<{ withWrapper?: boolean }>`
  width: calc(370 / 1440 * 100%);
  padding: ${({ theme }) => `60px ${theme.space.xl} 0px 20px`};

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    width: ${({ withWrapper }) => withWrapper && '100%'};
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    padding: 0;
    margin-top: ${({ theme }) => theme.space.l};
    width: ${({ withWrapper }) => withWrapper && '50%'};
    padding-right: 12px;
  }
`;

export const SidebarWrapper = styled.div<{
  ignoreWrapperStyles?: boolean;
  withWrapper?: boolean;
}>`
  ${({ ignoreWrapperStyles }) => !ignoreWrapperStyles && WrapperStyles}
`;
