import * as React from 'react';
import styled from 'styled-components';
import H3 from './typography/H3';
import { useFetchTopic } from '../hooks/useFetchData';
import TopicModal from './modal/TopicModal';
import { getColorFromType } from '../helpers/alarms';
import { useTheme } from 'styled-components';

const AlarmItemWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.space.md};
`;

const Expander = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: row;
  border-left-width: 2px;
  padding: ${({ theme }) => `${theme.space.xxxs} 10px ${theme.space.xxxs}`};
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: ${({ theme, expanded }) => expanded && theme.space.m};
`;

const ExpanderInfo = styled.div`
  flex: 1;
  max-width: 480px;
`;

const StyledH3 = styled(H3)`
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.03em;
  margin-bottom: ${({ theme }) => theme.space.xxxs};
`;

const IdNumber = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 13.8px;
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  margin-bottom: ${({ theme }) => theme.space.xxxs};
`;

const Summary = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  line-height: 14.95px;
`;

const ExpanderIcon = styled.img<{ expanded: boolean }>`
  align-self: flex-end;
  width: 18px;
  height: 18px;
  margin: 13px;
  transform: ${({ expanded }) => expanded && 'rotate(-90deg)'};
`;

const Expanded = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.quintusGray20};
  padding: ${({ theme }) => theme.space.m};
  border-radius: 2px;
  width: 90%;
  margin-bottom: ${({ theme }) => theme.space.xs};

  &:last-child {
    margin-bottom: 48px;
  }
`;

const ExpandedListTitle = styled.h4`
  font-size: 13px;
  line-height: 14.95px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  margin-bottom: ${({ theme }) => theme.space.sm};
`;

const ArrowIcon = styled.img``;

const ExpandedContent: React.FC<{ topicId: string }> = ({ topicId }) => {
  const [showTopicModal, setShowTopicModal] = React.useState(false);
  const { isError, data } = useFetchTopic(topicId);
  const [content, setContent] = React.useState<{
    title: string;
    overview: string;
  }>({ title: '', overview: '' });

  const toggleTopicModal = React.useCallback(
    () => setShowTopicModal(!showTopicModal),
    [showTopicModal],
  );

  React.useEffect(() => {
    if (data) {
      const titleMatch = data.match(/<div class="title">(.*?)<\/div>/);
      const title = titleMatch ? titleMatch[1] : null;

      // Find <p> within <div> with classname === 'overview'
      const overviewDiv = data
        .split('<div class="')
        .find(div => div.indexOf('overview') > -1);
      const pArray = overviewDiv ? overviewDiv.match(/<p>(.*?)<\/p>/) : null;

      const overview = pArray && pArray.length > 1 ? pArray[1] : null;

      setContent({ title, overview });
    }
  }, [data]);

  if (!data || isError) {
    return null;
  }

  return (
    <>
      <Expanded onClick={toggleTopicModal}>
        <div
          style={{
            paddingRight: 42,
          }}
        >
          <ExpandedListTitle>{content.title}</ExpandedListTitle>
          {content.overview ? <Summary>{content.overview}</Summary> : null}
        </div>
        <ArrowIcon src="/images/arrow.svg" />
      </Expanded>
      <TopicModal
        content={data}
        isVisible={showTopicModal}
        closeHandler={toggleTopicModal}
      />
    </>
  );
};

type AlarmItemProps = {
  item: Quintus.SearchResult;
};

const AlarmItem: React.FC<AlarmItemProps> = ({ item }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { isError, data } = useFetchTopic(item.document.topicId);
  const [extractedData, setExtractedData] = React.useState<{
    shortDesc: string | null;
    expandedDataIds: string[];
    expandedContentOverview: string | null;
  }>({ shortDesc: null, expandedDataIds: [''], expandedContentOverview: null });
  const { colors } = useTheme();

  React.useEffect(() => {
    let expandedContentOverview = null;
    let shortDesc = null;
    if (data) {
      const matchedArray = data.match(/<div class="shortdesc">(.*?)<\/div>/);
      if (matchedArray?.length > 1) {
        shortDesc = matchedArray[1];
      }
      const expandedDataIds = data.match(/<a href="(.*?)"/g) ?? null;
      expandedContentOverview = data
        .split('<div class="')
        .find(div => div.indexOf('overview') > -1);

      if (expandedContentOverview) {
        expandedContentOverview =
          data.match(/<p>(.*?)<\/p>/)?.length > 1
            ? data.match(/<p>(.*?)<\/p>/)[1]
            : null;
      }
      setExtractedData({ shortDesc, expandedDataIds, expandedContentOverview });
    }
  }, [data]);

  if (!data || isError) {
    return null;
  }
  if (extractedData?.expandedDataIds === null) {
    return null;
  }

  return (
    <AlarmItemWrapper>
      <Expander
        onClick={() => setExpanded(!expanded)}
        expanded={expanded}
        style={{
          borderLeftColor: colors[getColorFromType(item.document.alarmlevel)],
        }}
      >
        <ExpanderInfo>
          <IdNumber>
            {item.document.alarmModule} {item.document.errorcode}
          </IdNumber>
          <StyledH3>{item.document.title}</StyledH3>
          {extractedData.shortDesc && (
            //Find better and safer solution than setting inner HTML
            <Summary
              dangerouslySetInnerHTML={{ __html: extractedData.shortDesc }}
            />
          )}
        </ExpanderInfo>
        <ExpanderIcon src="/images/expander-arrow.svg" expanded={expanded} />
      </Expander>
      {expanded && (
        <>
          {extractedData.expandedContentOverview && (
            <ExpandedListTitle
              style={{
                paddingLeft: 12,
              }}
            >
              {extractedData.expandedContentOverview}
            </ExpandedListTitle>
          )}
          {extractedData.expandedDataIds.map((id, index) => (
            <ExpandedContent
              topicId={
                id.match(/<a href="(.*?)"/)?.length > 1
                  ? id.match(/<a href="(.*?)"/)[1]
                  : ''
              }
              key={index}
            />
          ))}
        </>
      )}
    </AlarmItemWrapper>
  );
};

export default AlarmItem;
