import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSearchLink } from '../../helpers/link';

const Item = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.quintusGray};
  margin-right: ${({ theme }) => theme.space.md};

  &:focus {
    outline: none;
  }

  &:last-child {
    border-bottom: 1px solid transparent;
  }

  &:first-child {
    a {
      padding-top: 0;
    }
  }
`;

const StyledLinkItemChild = styled.div``;

const StyledLink = styled(Link)<{
  $focus: boolean;
  $inHeader: boolean | undefined;
}>`
  display: block;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ $inHeader }) => ($inHeader ? '16.1px' : '20.7px')};
  color: ${({ $focus, theme }) =>
    $focus ? theme.colors.quintusGreen : '#000'};
  padding: 11px 0;

  &:focus {
    outline: none;
  }
`;

const StyledLinkItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type LinkItemProps = {
  title: string;
  type?: string;
};

const LinkItem: React.FC<LinkItemProps> = ({ title }) => {
  return (
    <StyledLinkItem>
      <StyledLinkItemChild>{`${title.charAt(0).toUpperCase()}${title
        .slice(1)
        .toLowerCase()}`}</StyledLinkItemChild>
    </StyledLinkItem>
  );
};

type SearchBoxResultsItemProps = {
  data: Quintus.SearchResultDocument;
  focus: boolean;
  index: number;
  setFocus: Dispatch<SetStateAction<number>>;
  inHeader?: boolean;
};

const SearchResultItem: React.FC<SearchBoxResultsItemProps> = ({
  data,
  focus,
  index,
  setFocus,
  inHeader,
}) => {
  const ref = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    if (focus && ref.current !== null) {
      ref.current.focus();
    }
  }, [focus, ref]);

  const handleSelect = () => {
    setFocus(index);
  };

  return (
    <Item tabIndex={focus ? 0 : -1} role="button" onMouseOver={handleSelect}>
      <StyledLink
        $focus={focus}
        ref={ref}
        to={useSearchLink(data)?.link}
        $inHeader={inHeader}
      >
        <LinkItem title={data.title} type={data.mapfile} />
      </StyledLink>
    </Item>
  );
};

export default SearchResultItem;
