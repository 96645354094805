import * as React from 'react';
import Page from 'src/components/layout/Page';
import PageContentWrapper from 'src/components/layout/PageContentWrapper';
import { useTranslation } from 'src/hooks/useTranslation';
import styled from 'styled-components';
import SearchPageMain from '../components/search/SearchPageMain';

const Paragraph = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.quintusGray};
  margin-bottom: ${({ theme }) => theme.space.m};
`;

const StyledImage = styled.img`
  width: 100%;
`;

const Search: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <PageContentWrapper flex={1}>
        <SearchPageMain
          breadCrumb={{ label: t('search.searhLabel'), url: '/search' }}
          title={t('search.title')}
          type="search"
          filters={[
            {
              label: 'Sub category',
              value: 'sub-category',
            },
            {
              label: 'Topic',
              value: 'topic',
            },
            {
              label: 'Concept',
              value: 'concept',
            },
            {
              label: 'Task',
              value: 'task',
            },
          ]}
          noActiveSearchComponent={
            <>
              <Paragraph>{t('search.overviewText')}</Paragraph>
              <StyledImage src="/images/search-page.png" />
            </>
          }
        />
      </PageContentWrapper>
    </Page>
  );
};

export default Search;
