import styled from 'styled-components';

export const MainContent = styled.div`
  width: calc(700 / 1440 * 100%);

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    width: calc(700 / 1024 * 100%);
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    width: 100%;
  }
`;
