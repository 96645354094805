import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { GAEvent } from 'src/helpers/analytics';
import { isPositiveInteger } from 'src/helpers/utils';
import api from 'src/services/api';
import { useConfig } from './useAuth';

export default function useSearch(
  term: string,
  parsedFilter: string,
  manifestId: string,
  type: Quintus.SearchTypes,
  searchOnErrorCode?: boolean,
): UseInfiniteQueryResult<Quintus.SearchResults> {
  const config = useConfig();

  const search = async ({ pageParam = 1 }) => {
    if (type === 'alarms') {
      if (searchOnErrorCode && term.length > 0) {
        const response = await api.get(
          `/search/v1/Index/${config.configuration.content_index}/objects?page=${pageParam}&orderBy=errorcode,alarmModule&size=20&term=*&filter=templateId eq '1078' and errorcode eq ${term} and ${parsedFilter}`,
        );
        if (response) {
          if (pageParam === 1) {
            GAEvent(`errorcode search:`, term);
          }
          return response.data;
        } else {
          return null;
        }
      } else {
        const response = await api.get(
          `/search/v1/Index/${
            config.configuration.content_index
          }/objects?page=${pageParam}&orderBy=errorcode,alarmModule&size=20&term=${
            term ?? ''
          }*&filter=templateId eq '1078' and ${parsedFilter}`,
        );
        if (response) {
          if (pageParam === 1 && term.length > 2) {
            GAEvent(`${type} search:`, term);
          }
          return response.data;
        } else {
          return null;
        }
      }
    } else {
      const response = await api.get(
        `/search/v1/Index/${config.configuration.content_index}/objects?page=${pageParam}&size=20&term=${term}&filter=${parsedFilter}`,
      );
      if (response) {
        if (pageParam === 1 && term.length > 2) {
          GAEvent(`${type} search:`, term);
        }
        return response.data;
      } else {
        return null;
      }
    }
  };

  return useInfiniteQuery<Quintus.SearchResults>(
    [type, searchOnErrorCode, term, manifestId, parsedFilter],
    search,
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.count - allPages?.length * 20 > 0) {
          return allPages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled:
        (type === 'search' && term.length > 2) ||
        (type === 'alarms' && term.length === 0) ||
        (type === 'alarms' && searchOnErrorCode && isPositiveInteger(term)) ||
        (type === 'alarms' && !searchOnErrorCode && term.length > 2),
      staleTime: 360000,
      retry: false,
    },
  );
}
