import * as React from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import StringToComponent from '../StringToComponent';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 76px;
  margin-top: ${({ theme }) => theme.space.md};
  overflow-y: auto;
  max-height: 80vh;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    padding: ${({ theme }) => `0 ${theme.space.l}`};
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.quintusListBorder};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.quintusGray};
  }
`;

type TopicModalProps = {
  content: string;
  isVisible: boolean;
  closeHandler: () => void;
};

const TopicModal: React.FC<TopicModalProps> = ({
  content,
  isVisible,
  closeHandler,
}) => {
  return (
    <Modal isVisible={isVisible} onClose={closeHandler}>
      <Wrapper>
        <StringToComponent data={content} isInModal={true} />
      </Wrapper>
      <div className="foo"></div>
    </Modal>
  );
};

export default TopicModal;
