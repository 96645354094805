import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../hooks/useTranslation';
import { useInternalLink } from '../hooks/useInternalLink';
import { Link } from 'react-router-dom';

const GreaterThanIcon = styled.img`
  width: 7px;
  height: 12px;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    margin-right: 8px;
    width: 8px;
  }
`;

const Crumbs = styled.ul`
  display: flex;
  align-items: center;
  padding: 19px 0;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    flex-wrap: wrap;
  }
`;

const Crumb = styled.li`
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: 400;
  line-height: 16.1px;
  padding: ${({ theme }) => `0 ${theme.space.s}`};
  color: #023e84;

  &:first-child {
    padding-left: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.quintusGreen};
  }
  &:last-child {
    color: #49515b;
  }

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    font-size: ${({ theme }) => theme.fontSizes.xxs};
    padding: 0 8px 0 0;
  }
`;

type BreadcrumbsProps = {
  breadcrumbs?: Quintus.BreadCrumb[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs = [] }) => {
  const { t } = useTranslation();
  const { internalLink } = useInternalLink();
  const crumbs: Quintus.BreadCrumb[] = [
    { label: t('breadcrumbs.home'), url: '/' },
    ...breadcrumbs,
  ];

  return (
    <Crumbs>
      {crumbs.map((crumb, index) => (
        <React.Fragment key={index}>
          <Crumb>
            <Link to={internalLink(crumb.url)}>{crumb.label}</Link>
          </Crumb>
          {crumbs.length > 1 && crumbs.length - 1 !== index && (
            <GreaterThanIcon src="/images/greater-than.svg" />
          )}
        </React.Fragment>
      ))}
    </Crumbs>
  );
};
export default Breadcrumbs;
