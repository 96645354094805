import styled from 'styled-components';

export default styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  line-height: ${({ theme }) => theme.lineHeights.s};
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
`;
