import { UserContext } from 'src/context/UserContext';
import { LanguageContext } from 'src/context/LanguageContext';
import { useQuery, UseBaseQueryResult } from 'react-query';
import axios from 'axios';
import { useConfig } from './useAuth';
import { useParams } from 'react-router-dom';
import api, { getToken } from 'src/services/api';
import { useContext } from 'react';

export type Link = {
  title: string;
  imageId: string;
  target: string;
  icon: string;
  type: string;
};

type HomePageData = {
  status: number;
  data: {
    heroImageId: string;
    productImageId: string;
    description: string;
    title: string;
    links: Link[];
    featuredArticles: Link[];
  };
};

export function useFetchStartPage(): UseBaseQueryResult<HomePageData> {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery<HomePageData>(['startpage', manifestId], () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/startpage.json?${getToken(
        'storageToken',
      )}`,
      baseURL: config.configuration.storage_url,
    })
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => error.response),
  );
}

export function useFetchSettings() {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery(['settings', manifestId], () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/settings.json?${getToken('storageToken')}`,
      baseURL: config.configuration.storage_url,
    })
      .then(response => response.data)
      .catch(error => error.response),
  );
}

type NodeResponse = Quintus.Node & { status?: number };

export function useFetchNode(
  browseId: string,
): UseBaseQueryResult<NodeResponse> {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery<NodeResponse>(['browse', browseId, manifestId], () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/browse.json?${getToken('storageToken')}`,
      baseURL: config.configuration.storage_url,
    })
      .then(response => response.data)
      .catch(error => error.response),
  );
}

type BrowseResponse = { data: Quintus.Node[]; status: number };

export function useFetchBrowse(): UseBaseQueryResult<BrowseResponse> {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery<BrowseResponse>(
    ['browse', manifestId],
    () =>
      axios({
        method: 'get',
        url: `/manifests/${manifestId}/browse.json?${getToken('storageToken')}`,
        baseURL: config.configuration.storage_url,
      })
        .then(response => {
          return { data: response.data, status: response.status };
        })
        .catch(error => error.response),
    { enabled: !!manifestId && !!config, staleTime: 360000 },
  );
}

export function useFetchTopic(topicId: string) {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery<string>(
    ['topic', manifestId, topicId],
    () =>
      axios({
        method: 'get',
        url: `/manifests/${manifestId}/html/${topicId}.html?${getToken(
          'storageToken',
        )}`,
        baseURL: config.configuration.storage_url,
      }).then(response => response.data),
    { enabled: !!manifestId && !!topicId && !!config, staleTime: 360000 },
  );
}

type IndexListResponse = { data: Quintus.Index[]; status: number };

export function useFetchIndexFiles(): UseBaseQueryResult<IndexListResponse> {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery<IndexListResponse>(['index', manifestId], () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/index.json?${getToken('storageToken')}`,
      baseURL: config.configuration.storage_url,
    })
      .then(response => {
        return { data: response.data, status: response.status };
      })
      .catch(error => error.response),
  );
}

export function useFetchMenuLOCAL(): UseBaseQueryResult<
  Quintus.DrawerMenuItem[]
> {
  return useQuery<Quintus.DrawerMenuItem[]>(['menu'], () =>
    axios({
      method: 'get',
      url: `/data/hamburger-menu.json`,
    })
      .then(response => response.data)
      .catch(error => error.response),
  );
}

export function useFetchProducts(): UseBaseQueryResult<Quintus.Product[]> {
  const { currentLanguage } = useContext(LanguageContext);
  const { hasFullAccess } = useContext(UserContext);

  return useQuery<Quintus.Product[]>(
    [hasFullAccess, 'products', currentLanguage],
    () =>
      api
        .get(
          hasFullAccess
            ? `/manifest/v1/configs?lang=${currentLanguage.code}`
            : `/manifest/v1/configs`,
        )
        .then(response => response.data),
    {
      enabled: !!currentLanguage,
      useErrorBoundary: true,
    },
  );
}

export function useFetchVideos(): UseBaseQueryResult<Quintus.VideoGallery> {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();
  return useQuery<Quintus.VideoGallery>(['videos', manifestId], () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/videogallery.json?${getToken(
        'storageToken',
      )}`,
      baseURL: config.configuration.storage_url,
    })
      .then(response => response.data)
      .catch(error => error.response),
  );
}

export function useFetchHotspots(): UseBaseQueryResult<Quintus.Hotspot[]> {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery<Quintus.Hotspot[]>(['hotspots', manifestId], () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/hotspots.json?${getToken('storageToken')}`,
      baseURL: config.configuration.storage_url,
    })
      .then(response => response.data)
      .catch(error => error.response),
  );
}

export function useProductConfigs(): UseBaseQueryResult<
  Quintus.ProductConfigItem[]
> {
  const config = useConfig();
  return useQuery<Quintus.ProductConfigItem[]>(
    ['product-configs'],
    () =>
      axios({
        method: 'get',
        url: `/configs/productconfigs.json`,
        baseURL: config.configuration.storage_url,
      })
        .then(response => response.data)
        .catch(error => error.response),
    { enabled: !!config },
  );
}

export function useFetchLanguagesLOCAL(): UseBaseQueryResult<
  Quintus.LanguageItem[]
> {
  return useQuery<Quintus.LanguageItem[]>(['languages'], () =>
    axios({
      method: 'get',
      url: `/data/languages.json`,
    })
      .then(response => response.data)
      .catch(error => error.response),
  );
}
