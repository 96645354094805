import * as React from 'react';
import {
  useFetchHotspots,
  useFetchMenuLOCAL,
  useFetchStartPage,
} from '../hooks/useFetchData';
import styled, { keyframes } from 'styled-components';
import { useParams } from 'react-router';
import { useInternalLink } from 'src/hooks/useInternalLink';
import SearchBox from './search/SearchBox';
import { useFetchBrowse } from 'src/hooks/useFetchData';
import { Link } from 'react-router-dom';
import { LanguageContext } from 'src/context/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'src/context/UserContext';
import { useTranslation } from 'src/hooks/useTranslation';
import { title } from 'process';

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const WrapperContent = styled.div`
  max-width: ${({ theme }) => theme.sizes.containerWide};
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Wrapper = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 291px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.quintusGray20};
  top: 0;
  right: 0;
  z-index: 999;
  padding: 0 15px 0 20px;

  //Padding to make up for when scrollbar appears
  @media (max-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding-right: calc(100% - 100vw + 15px);
  }
  animation: ${show} 0.5s;
`;

const CloseIcon = styled.img`
  width: 18px;
  align-self: flex-end;
  margin: 39px 9px 42px 0;
  cursor: pointer;
`;

const MenuList = styled.ul`
  padding: 0;
`;

const MenuItemWrapper = styled.li<{
  hasChildren: boolean;
  isSubItem: boolean;
  active?: boolean;
}>`
  display: flex;
  flex-direction: ${({ hasChildren }) => (hasChildren ? 'column' : 'row')};
  border-bottom-width: ${({ isSubItem }) => !isSubItem && '1px'};
  border-bottom-color: ${({ theme }) => theme.colors.quintusGray};
  padding: ${({ theme, isSubItem }) =>
    isSubItem ? `0 0 0 43px` : `${theme.space.xs}`};
  font-weight: ${({ active }) => (active ? '700' : '400')};

  &:first-child {
    border-top-width: ${({ isSubItem }) => !isSubItem && '1px'};
    border-top-color: ${({ theme }) => theme.colors.quintusGray};
  }
`;

const MenuItemIcon = styled.img`
  margin-right: ${({ theme }) => theme.space.m};
`;

const ExpanderIcon = styled.img<{ expanded: boolean }>`
  transform: ${({ expanded }) => expanded && 'rotate(-90deg)'};
`;

const MenuItemLink = styled(Link)`
  display: flex;
  padding: ${({ theme }) => `${theme.space.s} 0`};
  flex: 1;
  cursor: pointer;
`;

const LanguageItemLink = styled.a`
  display: flex;
  padding: ${({ theme }) => `${theme.space.s} 0`};
  flex: 1;
  cursor: pointer;
`;

type MenuItemProps = {
  item: Quintus.DrawerMenuItem;
  isSubItem?: boolean;
  onExpand?(): void;
  active?: boolean;
  showSubMenu?: boolean;
};

const MenuItem: React.FC<MenuItemProps> = ({
  item,
  isSubItem,
  onExpand,
  active,
  showSubMenu,
}) => {
  const { internalLink } = useInternalLink();
  const hasSubItems = item.children && item.children.length > 0;
  const { t } = useTranslation();
  return (
    <MenuItemWrapper
      hasChildren={hasSubItems}
      isSubItem={isSubItem}
      onClick={onExpand && onExpand}
      active={active}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
        }}
      >
        {item.icon && <MenuItemIcon src={`/images/${item.icon}.svg`} />}
        <MenuItemLink
          to={hasSubItems || isSubItem ? '#' : internalLink(`/${item.link}`)}
        >
          {t(item.title) ?? item.title}
        </MenuItemLink>
        {hasSubItems && (
          <ExpanderIcon
            src={`/images/expander-black.svg`}
            expanded={showSubMenu}
          />
        )}
      </div>
      {hasSubItems &&
        (item.title === 'menu.language' ? (
          <LanguageSubMenu visible={showSubMenu} />
        ) : (
          <SubMenu items={item.children} visible={showSubMenu} />
        ))}
    </MenuItemWrapper>
  );
};

const StyledMenuItem = styled(MenuItem)`
  border-bottom-width: 0;
`;

type SubMenuProps = {
  items: Quintus.DrawerMenuItem[];
  visible: boolean;
};

const SubMenu: React.FC<SubMenuProps> = ({ items, visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <MenuList>
      {items.map((item, index) => (
        <StyledMenuItem item={item} key={index} isSubItem={true} active />
      ))}
    </MenuList>
  );
};

type LanguageSubMenuProps = {
  visible: boolean;
};

const LanguageSubMenu: React.FC<LanguageSubMenuProps> = ({ visible }) => {
  const { productLanguages, currentLanguage, updateLanguage } =
    React.useContext(LanguageContext);
  const navigate = useNavigate();
  if (!visible) {
    return null;
  }

  return (
    <MenuList>
      {productLanguages.map((item, index) => (
        <MenuItemWrapper
          key={index}
          hasChildren={false}
          isSubItem={true}
          active={item.code === currentLanguage.code}
        >
          <LanguageItemLink
            onClick={() => {
              const manifestId = updateLanguage(item.code);

              if (manifestId) {
                navigate(`/${manifestId}`);
              }
            }}
          >
            {item.title}
          </LanguageItemLink>
        </MenuItemWrapper>
      ))}
    </MenuList>
  );
};

type MenuProps = {
  item: Quintus.DrawerMenuItem;
};

const Menu: React.FC<MenuProps> = ({ item }) => {
  const [showSubMenu, setShowSubMenu] = React.useState(false);

  const toggleSubMenu = React.useCallback(() => {
    setShowSubMenu(!showSubMenu);
  }, [showSubMenu]);

  return (
    <MenuItem
      item={item}
      onExpand={toggleSubMenu && toggleSubMenu}
      showSubMenu={showSubMenu}
    />
  );
};

const MainMenu = styled.ul`
  padding: 0;
  display: none;
  margin-bottom: ${({ theme }) => theme.space.l};

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    display: block;
  }
`;

const MainMenuItem = styled.li`
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  text-transform: uppercase;
  padding: ${({ theme }) => `${theme.space.xs} 0`};

  &:first-child {
    padding-top: 0;
  }
`;

type DrawerMenuProps = {
  visible: boolean;
  onClose(): void;
  drawerContent: 'menu' | 'search';
};

const DrawerMenu: React.FC<DrawerMenuProps> = ({
  visible,
  onClose,
  drawerContent,
}) => {
  const { t } = useTranslation();
  const { hasFullAccess } = React.useContext(UserContext);
  const { error, data } = useFetchMenuLOCAL();
  const { data: mainMenuItems } = useFetchBrowse();
  const { manifestId } = useParams<{ manifestId: string }>();
  const drawerRef = React.useRef(null);
  const { data: startPageData } = useFetchStartPage();

  const plantOverviewLink = startPageData?.data.featuredArticles.find(
    article => article.icon === 'Hotspot' && article.type === 'function',
  )?.target;

  const menuOverviewData = React.useMemo(() => {
    return data?.map(item => {
      if (item.title === 'menu.overview' && plantOverviewLink) {
        return {
          ...item,
          link: `hotspot/${plantOverviewLink}`,
        };
      }
      return item;
    });
  }, [data, plantOverviewLink]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [drawerRef, onClose]);

  if (!visible || error || !data) {
    return null;
  }

  return (
    <WrapperContent>
      <Wrapper visible={visible} ref={drawerRef}>
        <CloseIcon src="/images/close.svg" onClick={onClose} />
        {drawerContent === 'menu' ? (
          <>
            <MainMenu>
              {mainMenuItems?.data.map((item, index) => (
                <MainMenuItem key={index}>
                  <Link to={`/${manifestId}/browse/${item.topicId}`}>
                    {item.title}
                  </Link>
                </MainMenuItem>
              ))}
            </MainMenu>
            <MenuList>
              {menuOverviewData.length > 0 &&
                menuOverviewData.map((item, index) => (
                  <Menu item={item} key={index} />
                ))}
              {hasFullAccess && (
                <Menu
                  item={{
                    title: t('menu.logout') ?? 'menu.logout',
                    icon: 'logout',
                    link: 'logout',
                  }}
                />
              )}
            </MenuList>
          </>
        ) : (
          <SearchBox type="search" />
        )}
      </Wrapper>
    </WrapperContent>
  );
};

export default DrawerMenu;
