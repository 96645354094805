import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useInternalLink } from '../hooks/useInternalLink';

const Wrapper = styled.div`
  max-width: 640px;
  margin-bottom: 40px;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.ml};
  line-height: 20.7px;
  letter-spacing: 0.04%;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
`;

const StyledLink = styled(Link)`
  display: block;
  border-top: 1px solid ${({ theme }) => theme.colors.quintusListBorder};

  &:first-child {
    border-top-width: 0;
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.quintusListBorder};
  }

  &:hover {
    ${Title} {
      color: ${({ theme }) => theme.colors.quintusGreen};
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.xxs}`};
  justify-content: space-between;
`;

const ItemLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: ${({ theme }) => theme.space.md};
`;

const ArrowIcon = styled.img`
  width: 16px;
  margin-left: ${({ theme }) => theme.space.s};
`;

type LinkListProps = {
  items: Quintus.Node[];
  parent?: string;
};

const LinkList: React.FC<LinkListProps> = ({ items, parent }) => {
  const { internalLink } = useInternalLink();
  if (items.length < 1) {
    return null;
  }

  const getHref = (hasChildren: boolean | undefined, title: string): string => {
    return hasChildren
      ? `/browse/${parent}/${title}`
      : `/${parent}/topics/${title}`;
  };

  return (
    <Wrapper>
      {items.map((item, index) => (
        <StyledLink
          to={internalLink(
            getHref(
              item.children && item.children.length > 0,
              item.topicId ?? '',
            ),
          )}
          key={index}
        >
          <Item>
            <ItemLeft>
              <Icon
                src={`/images/${
                  item.children && item.children.length > 0 ? 'browse' : 'topic'
                }.svg`}
              />
              <Title>{item.title}</Title>
            </ItemLeft>
            <ArrowIcon src="/images/arrow.svg" />
          </Item>
        </StyledLink>
      ))}
    </Wrapper>
  );
};

export default LinkList;
