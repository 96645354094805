import React from 'react';

export interface UserContextInterface {
  hasFullAccess: boolean;
  setHasFullAccess: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const UserContext = React.createContext<UserContextInterface>({
  hasFullAccess: false,
  setHasFullAccess: () => console.log(''),
});

const UserContextProvider: React.FC = ({ children }) => {
  const [hasFullAccess, setHasFullAccess] = React.useState<boolean>(false);

  const value = React.useMemo(
    () => ({ hasFullAccess, setHasFullAccess }),
    [hasFullAccess],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContextProvider, UserContext };
