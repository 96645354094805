import * as React from 'react';
import styled, { css } from 'styled-components';

const InactiveStyle = css`
  border: 1px solid ${({ theme }) => theme.colors.quintusGray40};
  color: ${({ theme }) => theme.colors.quintusGray40};
  pointer-events: none;
`;

const ButtonWrapper = styled.button<{ inactive: boolean; hasIcon: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px ${({ theme }) => theme.space.xs};
  border-radius: ${({ theme }) => theme.radii.xs};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  line-height: ${({ theme }) => theme.lineHeights.xxxs};
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.colors.quintusBlue};
  color: ${({ theme }) => theme.colors.quintusBlue};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.quintusGreen};
    color: ${({ theme }) => theme.colors.quintusGreen};
  }

  ${({ inactive }) => inactive && InactiveStyle};
`;

type ButtonProps = {
  text: string;
  icon?: React.ReactChild;
  onClick: () => void;
};

const Button: React.FC<ButtonProps> = ({ text, icon, onClick }) => {
  return (
    <ButtonWrapper
      inactive={false}
      hasIcon={icon ? true : false}
      onClick={onClick}
    >
      {icon && icon}
      {text}
    </ButtonWrapper>
  );
};

export default Button;
