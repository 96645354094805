import * as React from 'react';
import Page from '../components/layout/Page';
import { PageContent } from '../components/layout/PageContent';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import styled from 'styled-components';
import SearchBox from 'src/components/search/SearchBox';
import {
  useFetchStartPage,
  useFetchBrowse,
  Link as LinkModel,
} from 'src/hooks/useFetchData';
import Loader from 'src/components/Loader';
import { useLinkFromId } from '../helpers/link';
import { useInternalLink } from 'src/hooks/useInternalLink';
import { Navigate, Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  width: ${({ theme }) => theme.sizes.containerWide};
  max-width: 100%;
  align-self: center;
  box-shadow: 0px 0px 10px -5px rgb(0 0 0 / 25%);
`;

const TopWrapper = styled.div`
  flex: 1 0 40%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('/images/startpagebackground.svg');
  background-position: 50% 50%;
`;

const Footer = styled.div`
  flex: 1 0 60%;
  background-color: ${({ theme }) => theme.colors.quintusStartpageBg};
  display: flex;
  justify-content: center;
`;

const ProductTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  position: absolute;
  top: 30%;
  font-weight: 400;
  font-size: 38px;
  text-transform: uppercase;
  line-height: 43.7px;

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    padding: 0 110px;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    padding: 0 95px;
  }
`;

const ManualLinks = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    width: 60%;
    margin: 0 54px;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    width: 82%;
    margin: 0 39px;
  }
`;

const ManualLinksItem = styled.li`
  flex: 1;
  padding: ${({ theme }) => `0 ${theme.space.xs}`};

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    flex: 0 0 33%;
    margin-bottom: ${({ theme }) => theme.space.l};
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LinkText = styled.span`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.ml};
  line-height: 20.88px;
  letter-spacing: 0.05em;
  font-weight: 700;
  color: white;
  text-align: center;
`;

const SearchBoxWrapper = styled.div`
  width: 287px;
  margin: 70px 0 98px;

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    margin: 70px 110px 77px 110px;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    margin: 70px 95px 77px 95px;
  }
`;

const ManualLinksItemLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 75px;
  background-color: ${({ theme }) => theme.colors.quintusGray};
  border-radius: 50%;
  margin-bottom: ${({ theme }) => theme.space.m};
`;

const LinkIcon = styled.img`
  max-width: 30px;
`;

const Home: React.FC = () => {
  const { isLoading, isError, data } = useFetchStartPage();

  if (data?.status === 404) {
    return <Navigate to="/" />;
  }

  return (
    <Page hideSearch={true} hideFooter={true}>
      {isLoading || data === undefined || isError ? null : (
        <Wrapper>
          <TopWrapper>
            <PageContentWrapper
              flex={1}
              withoutBgColor={true}
              withoutPadding={true}
            >
              <PageContent width="1040px">
                <ProductTitle>{data.data.title}</ProductTitle>
              </PageContent>
            </PageContentWrapper>
          </TopWrapper>
          <Footer>
            <PageContentWrapper
              alignSelf="flex-start"
              withoutBgColor={true}
              withoutPadding={true}
            >
              <PageContent width="1040px">
                <SearchBoxWrapper>
                  <SearchBox type="search" />
                </SearchBoxWrapper>
              </PageContent>
              <PageContent width="1170px">
                <ManualLinks>
                  {data.data.featuredArticles.map((link, index) => (
                    <Item item={link} key={index} />
                  ))}
                </ManualLinks>
              </PageContent>
            </PageContentWrapper>
          </Footer>
        </Wrapper>
      )}
    </Page>
  );
};

type ItemProps = {
  item: LinkModel;
};

const Item: React.FC<ItemProps> = ({ item }) => {
  const { data: browseData } = useFetchBrowse();
  const { internalLink } = useInternalLink();
  const idLink = useLinkFromId(browseData?.data, item.target);

  const topicLink = (item: LinkModel) => {
    let link: string;

    switch (item.type) {
      case 'function':
        link = internalLink(
          `/${item.icon.toLowerCase()}/${
            item.icon.toLocaleLowerCase() != 'alarms' ? item.target : ''
          }`,
        );
        break;

      default:
        link = idLink;
        break;
    }

    return link;
  };

  return (
    <ManualLinksItem>
      <StyledLink to={topicLink(item)}>
        <ManualLinksItemLogo>
          <LinkIcon src={`/images/${item.icon}-light.svg`} />
        </ManualLinksItemLogo>
        <LinkText>{item.title}</LinkText>
      </StyledLink>
    </ManualLinksItem>
  );
};

export default Home;
