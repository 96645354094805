import * as React from 'react';
import styled from 'styled-components';
import { GAEvent } from '../helpers/analytics';
import Button from './Button';
import FeedbackModal from './modal/FeedbackModal';
import { useTranslation } from '../hooks/useTranslation';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.div``;

const Text = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.quintusBlue};
  font-weight: 700;
  padding: ${({ theme }) => theme.space.s} 0;
`;

const ButtonWrapper = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;

  & button:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const ThankYou = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  color: ${({ theme }) => theme.colors.quintusBlue};
`;

const FeedBack: React.FC = () => {
  const location = useLocation();

  //Reset feedback state when moving directly between topics
  React.useEffect(() => {
    setDisplayResponse(false);
  }, [location]);

  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const [displayResponse, setDisplayResponse] = React.useState(false);
  const clickHandler = React.useCallback(
    (show: boolean, toggleThanks?: boolean): void => {
      setShowModal(show);
      if (toggleThanks) setDisplayResponse(true);
    },
    [],
  );
  return (
    <>
      <Wrapper>
        <Text>{t('feedback.text')}</Text>
        <ButtonWrapper visible={!displayResponse}>
          <Button
            text={t('feedback.yes')}
            onClick={() => {
              GAEvent('Feedback', 'Provided positive feedback');
              setDisplayResponse(true);
            }}
          />
          <Button text={t('feedback.no')} onClick={() => clickHandler(true)} />
        </ButtonWrapper>
        <ThankYou visible={displayResponse}>{t('feedback.thankYou')}</ThankYou>
        <FeedbackModal
          isVisible={showModal}
          closeHandler={feedbackProvided =>
            clickHandler(false, feedbackProvided)
          }
        />
      </Wrapper>
    </>
  );
};

export default FeedBack;
