import * as React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.quintusStartpageBg};

  //Padding to make up for when scrollbar appears
  @media (min-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding-left: calc(100vw - 100%);
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  width: ${({ theme }) => theme.sizes.containerWide};
  padding: ${({ theme }) => theme.space.md};
  justify-content: flex-end;
`;

const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  letter-spacing: 0.1em;
`;

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <InnerWrapper>
        <Text>Quintus Technologies AB</Text>
      </InnerWrapper>
    </FooterWrapper>
  );
};

export default Footer;
