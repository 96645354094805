import * as React from 'react';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import Page from '../components/layout/Page';
import { PageContent } from '../components/layout/PageContent';
import Breadcrumbs from 'src/components/Breadcrumbs';
import H1 from '../components/typography/H1';
import styled, { css } from 'styled-components';
import { useFetchVideos } from '../hooks/useFetchData';
import { getToken } from 'src/services/api';
import VideoModal from '../components/modal/VideoModal';
import { useConfig } from '../hooks/useAuth';

type Direction = 'column' | 'row';

const ColumnItemStyle = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: ${({ theme }) => theme.space.xl};
  padding-right: ${({ theme }) => theme.space.xxxl};
`;

const RowItemStyle = css`
  flex: 0 0 33.33%;
  padding: 0 9px;
  margin-bottom: ${({ theme }) => theme.space.xl};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  padding-left: ${({ theme }) => theme.space.sm};
  width: 60%;
`;

const VideoItemWrapper = styled.div<{ direction: Direction }>`
  ${({ direction }) => (direction === 'row' ? RowItemStyle : ColumnItemStyle)};
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.1px;
  font-weight: 700;
  letter-spacing: 0.03em;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const PlayIcon = styled.img`
  position: absolute;
`;

// const Link = styled.a`
//   display: flex;
//   align-items: center;
//   font-size: ${({ theme }) => theme.fontSizes.xxs};
//   line-height: 14.95px;
//   font-weight: 700;
//   color: ${({ theme }) => theme.colors.quintusDarkBlue};
//   cursor: pointer;

//   &:after {
//     content: '';
//     background: url(/images/arrow.svg);
//     background-repeat: no-repeat;
//     background-size: contain;
//     width: 11px;
//     height: 8px;
//     margin-left: ${({ theme }) => theme.space.sm};
//   }

//   &:hover {
//     color: ${({ theme }) => theme.colors.quintusGreen};

//     &:after {
//       background: url(/images/arrow-active.svg);
//       background-repeat: no-repeat;
//       background-size: contain;
//       width: 11px;
//       height: 8px;
//     }
//   }
// `;

const PosterImageWrapper = styled.div<{ direction: Direction }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 204px;
  margin-bottom: ${({ direction, theme }) =>
    direction === 'row' && theme.space.xs};
  border-radius: 2px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 130px;
`;

type VideoItemProps = {
  item: Quintus.Video;
  direction: Direction;
};

const VideoItem: React.FC<VideoItemProps> = ({ item, direction }) => {
  const [showVideoModal, setShowVideoModal] = React.useState(false);
  const config = useConfig();

  const handleClick = React.useCallback(
    () => setShowVideoModal(!showVideoModal),
    [showVideoModal],
  );

  return (
    <>
      <VideoItemWrapper direction={direction} onClick={handleClick}>
        <PosterImageWrapper
          direction={direction}
          style={{
            backgroundImage: `url(${config?.configuration.storage_url}${
              item.posterId
            }.jpg?${getToken('storageToken')})`,
          }}
        >
          <PlayIcon src="/images/play.svg" />
        </PosterImageWrapper>
        {direction === 'column' && (
          <ColumnWrapper>
            <div>
              <Title>{item.title}</Title>
            </div>
          </ColumnWrapper>
        )}
      </VideoItemWrapper>
      <VideoModal
        isVisible={showVideoModal}
        closeHandler={handleClick}
        posterId={item.posterId}
        filename={item.filename}
      />
    </>
  );
};

const OuterWrapper = styled.div<{ direction: Direction }>`
  width: ${({ direction }) => (direction === 'column' ? '585px' : '700px')};
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    width: 80%;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    width: 100%;
  }
`;

const InnerWrapper = styled.div<{ direction: Direction }>`
  max-height: 100vh;
  overflow-y: auto;
  padding-right: ${({ direction }) => direction === 'row' && '37px'};

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.quintusListBorder};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.quintusGray};
  }
`;

const Videos = styled.div<{ direction: Direction }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ direction }) => (direction === 'row' ? 'wrap' : 'nowrap')};
  margin: ${({ direction }) => direction === 'row' && '0 -9px'};
`;

const StyledH1 = styled(H1)`
  margin: ${({ theme }) => `${theme.space.xxl} 0 12px 0`};
`;

const Overview = styled.div`
  margin-bottom: ${({ theme }) => theme.space.xxl};
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.space.s};
`;

const DirectionIcon = styled.img<{ direction: Direction }>`
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
`;

const Video: React.FC = () => {
  const { error, data } = useFetchVideos();
  const [direction, setDirection] = React.useState<Direction>('column');

  return (
    <Page>
      <PageContentWrapper flex={1}>
        {error || !data ? null : (
          <PageContent width="1070px" alignMent="flex-end">
            <Breadcrumbs breadcrumbs={[{ label: data.title, url: 'index' }]} />
            <StyledH1>{data.title}</StyledH1>
            <Overview>{data.description}</Overview>
            <OuterWrapper direction={direction}>
              <IconsWrapper>
                <DirectionIcon
                  src={`/images/${
                    direction === 'column' ? 'column' : 'column-light'
                  }.svg`}
                  onClick={() => setDirection('column')}
                  direction={direction}
                />
                <DirectionIcon
                  src={`/images/${
                    direction === 'row' ? 'row' : 'row-light'
                  }.svg`}
                  onClick={() => setDirection('row')}
                  direction={direction}
                />
              </IconsWrapper>
              {1 > 0 && (
                <InnerWrapper direction={direction}>
                  <Videos direction={direction}>
                    {data.videos?.map((video, index) => (
                      <VideoItem
                        item={video}
                        key={index}
                        direction={direction}
                      />
                    ))}
                  </Videos>
                </InnerWrapper>
              )}
            </OuterWrapper>
          </PageContent>
        )}
      </PageContentWrapper>
    </Page>
  );
};

export default Video;
