import * as React from 'react';
import { useQuery } from 'react-query';
import { useConfig } from './useAuth';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getToken } from 'src/services/api';
import { LanguageContext } from 'src/context/LanguageContext';

export function useTranslation(isLogin?: boolean) {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();
  const { currentLanguage } = React.useContext(LanguageContext);

  const manifestFetch = () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/strings.json?${getToken('storageToken')}`,
      baseURL: config.configuration.storage_url,
    }).then(response => {
      const returnData = {};
      Object.keys(response.data).map(key => {
        returnData[key.toLocaleLowerCase()] = response.data[key];
      });

      return returnData;
    });

  const loginFetch = () =>
    axios({
      method: 'get',
      url: `/configs/strings_${currentLanguage.code}.json`,
      baseURL: config.configuration.storage_url,
    }).then(response => {
      const returnData = {};
      Object.keys(response.data).map(key => {
        returnData[key.toLocaleLowerCase()] = response.data[key];
      });
      return returnData;
    });

  const { data } = useQuery<{ key: string } | Record<string, never>>(
    ['strings', isLogin ? currentLanguage?.code : manifestId],
    isLogin ? loginFetch : manifestFetch,
    isLogin
      ? { enabled: !!config && !!currentLanguage }
      : { enabled: !!config && !!manifestId },
  );

  const t = React.useCallback(
    (key: string) => {
      if (data) {
        if (!data[key.toLocaleLowerCase()]) {
          return null;
        }
        return data[key.toLocaleLowerCase()];
      }
    },
    [data],
  );
  return { t };
}
