import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import H3 from '../components/typography/H3';
import { useSearchLink } from '../helpers/link';

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  cursor: pointer;
`;

const ParentTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14.8px;
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  margin-bottom: ${({ theme }) => theme.space.xs};
`;

const TitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.space.xs};
  display: flex;
  align-items: center;
`;

const StyledH3 = styled(H3)`
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.03em;
`;

const ContentText = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  line-height: 14.95px;
`;

const TitleIcon = styled.img`
  width: 12px;
  height: 16px;
  margin: ${({ theme }) => `0 ${theme.space.xxs}`};
`;

type SearchResultItemProps = {
  item: Quintus.SearchResultDocument;
};

const SearchResultListItem: React.FC<SearchResultItemProps> = ({ item }) => {
  const linkData = useSearchLink(item);

  return (
    <Wrapper to={linkData.link}>
      <ParentTitle>{linkData?.title && linkData.title}</ParentTitle>
      <TitleWrapper>
        <TitleIcon
          src={
            item.topicType === 'sub-category'
              ? '/images/browse.svg'
              : '/images/topic.svg'
          }
        />
        <StyledH3>{item.title}</StyledH3>
      </TitleWrapper>
      <ContentText>{item.content.slice(0, 200)}</ContentText>
    </Wrapper>
  );
};

export default SearchResultListItem;
