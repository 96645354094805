import * as React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{
  flex: number;
  alignSelf: string;
  withoutPadding: boolean;
  withoutBgColor: boolean;
  centerContent: boolean;
}>`
  max-width: ${({ theme }) => theme.sizes.containerWide};
  width: 100%;
  display: flex;
  align-items: ${({ centerContent }) => centerContent && 'center'};
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : 'center')};
  flex-direction: column;
  flex: ${({ flex }) => flex && flex};
  background-color: ${({ withoutBgColor }) => !withoutBgColor && '#fff'};
  box-shadow: ${({ withoutBgColor }) =>
    !withoutBgColor && '0px 0px 10px -5px rgb(0 0 0 / 25%)'};

  ${({ withoutPadding }) =>
    !withoutPadding &&
    css`
      padding-top: 57px;
      padding-bottom: 80px;
    `}

  @media (max-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding: ${({ theme }) => `42.5px ${theme.space.l} 80px ${theme.space.l}`};
  }
`;

type PageContentWrapperProps = {
  flex?: number;
  alignSelf?: 'flex-start';
  withoutPadding?: boolean;
  withoutBgColor?: boolean;
  centerContent?: boolean;
};

const PageContentWrapper: React.FC<PageContentWrapperProps> = ({
  children,
  flex,
  alignSelf,
  withoutPadding,
  withoutBgColor,
  centerContent,
}) => (
  <Wrapper
    flex={flex}
    alignSelf={alignSelf}
    withoutPadding={withoutPadding}
    withoutBgColor={withoutBgColor}
    centerContent={centerContent}
  >
    {children}
  </Wrapper>
);

export default PageContentWrapper;
