import styled from 'styled-components';

export const PageContentRow = styled.div<{ center?: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: ${({ center }) => center && 'center'};

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    flex-direction: column;
  }
`;
