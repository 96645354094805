import styled from 'styled-components';

export default styled.p`
  margin-bottom: ${({ theme }) => theme.space.s};
  max-width: 100%;

  li & {
    margin-bottom: unset;
  }

  td & {
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: 1.43;
    &:only-of-type {
      margin-bottom: unset;
      margin-top: ${({ theme }) => theme.space.xs};
    }
  }

  .attention & {
    max-width: unset;
    &:last-of-type {
      margin-bottom: unset;
    }
  }
`;
