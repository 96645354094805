import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchProducts } from 'src/hooks/useFetchData';
import { useTranslation } from 'src/hooks/useTranslation';
import styled, { keyframes } from 'styled-components';
import { storeProduct, storeProductTitle } from '../helpers/products';
import Loader from './Loader';

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const ProductItem = styled.li`
  margin: ${({ theme }) => `${theme.space.xxxs} 0`};
  padding-right: 30px;

  &:first-child {
    margin-top: 0;
  }
`;

const ProductItemLink = styled.a`
  border-width: 1px;
  border-color: #fff;
  border-radius: 1px;
  height: 61px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 18.4px;
  color: #fff;
  padding: ${({ theme }) => `0 ${theme.space.l} 0 18px`};
  justify-content: space-between;
`;

const ArrowIcon = styled.img``;

const Product: React.FC<{ product: Quintus.Product }> = ({ product }) => {
  const navigate = useNavigate();

  return (
    <ProductItem>
      <ProductItemLink
        href="#"
        onClick={() => {
          storeProduct(product.manifestId);
          storeProductTitle(product.description);
          navigate(`/${product.manifestId}`);
        }}
      >
        <span>{product.description}</span>
        <ArrowIcon src="/images/arrow-light.svg" />
      </ProductItemLink>
    </ProductItem>
  );
};

const Wrapper = styled.div<{ isLoginPage: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.quintusDarkBlue};
  width: 604px;
  max-width: 100%;
  padding: ${({ isLoginPage }) =>
    isLoginPage ? '0 0 8px 0' : '23px 32px 13px 48px'};
  animation: ${show} 0.5s;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 23px;
  color: #fff;
  text-transform: uppercase;
  margin: 23px 0 28px;
`;

const StartpageTitle = styled(Title)`
  margin: 31px 0 52px 0;
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;

const Products = styled.ul<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: auto;
  padding: 0;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.quintusListBorder};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #fff;
  }
`;

type ProductSelectorProps = {
  visible: boolean;
  maxHeight: string;
  isLoginPage?: boolean;
  onClose?: () => void;
};

const ProductSelector: React.FC<ProductSelectorProps> = ({
  visible,
  maxHeight,
  isLoginPage,
  onClose,
}) => {
  const { t } = useTranslation(true);
  const { error, data } = useFetchProducts();

  if (error || !data || !visible) {
    return null;
  }
  return (
    <Wrapper isLoginPage={isLoginPage}>
      <Header>
        {isLoginPage ? (
          <StartpageTitle>
            {t('productSelector.title') ?? 'Choose your product'}
          </StartpageTitle>
        ) : (
          <>
            <Title>{t('productSelector.title')}</Title>
            <CloseIcon src="/images/close-light.svg" onClick={onClose} />
          </>
        )}
      </Header>
      {data ? (
        <Products maxHeight={maxHeight}>
          {data.map((product, index) => (
            <Product product={product} key={index} />
          ))}
        </Products>
      ) : (
        <Loader></Loader>
      )}
    </Wrapper>
  );
};

export default ProductSelector;
