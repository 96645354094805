import React from 'react';
import styled from 'styled-components';
import Page from 'src/components/layout/Page';
import PageContentWrapper from 'src/components/layout/PageContentWrapper';
import ProductSelector from '../components/ProductSelector';
import { useFetchProducts } from 'src/hooks/useFetchData';
import { useLocation, useNavigate } from 'react-router-dom';
import { LanguageContext } from 'src/context/LanguageContext';
import {
  getProduct,
  storeProduct,
  storeProductTitle,
} from 'src/helpers/products';
import AuthTokenLoader from 'src/components/AuthTokenLoader';

const LoginWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: url('/images/loginbackground.svg') no-repeat;
  background-size: cover;
  justify-content: center;
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  max-width: 100%;
  width: 604px;
  background-color: ${({ theme }) => theme.colors.quintusDarkBlue};
  margin-left: 150px;

  @media (max-width: 1200px) {
    margin: 0 auto;
  }
`;

const ProductWizard: React.FC = () => {
  const { error, data } = useFetchProducts();
  const { state } = useLocation() as any;
  const [initializing, setInitializing] = React.useState(true);
  const navigate = useNavigate();
  const { updateLanguage } = React.useContext(LanguageContext);

  React.useEffect(() => {
    const checkIsValidProduct = () => {
      try {
        const from = state?.from?.pathname;

        if (from) {
          const pathArray = from.split('/');

          const manifestId = pathArray[1];
          const foundProduct = data.find(
            product => product.manifestId === manifestId,
          );
          if (foundProduct) {
            storeProduct(foundProduct.manifestId);
            storeProductTitle(foundProduct.description);
            const prodArray = foundProduct.manifestId.split('_');
            const langCode = prodArray[1];
            updateLanguage(langCode);
            navigate(from);
            return;
          }
        }
        const storedProduct = getProduct();
        if (storedProduct) {
          navigate(`/${storedProduct}`);
        }
      } catch (error) {
        console.log(error);
        setInitializing(false);
      }
    };
    if (data) {
      checkIsValidProduct();
      setInitializing(false);
    }
  }, [data, navigate, state, updateLanguage]);

  return (
    <Page hideMenu={true} hideFooter={true} initializing={initializing}>
      {initializing ? (
        <PageContentWrapper flex={1} centerContent>
          <AuthTokenLoader />
        </PageContentWrapper>
      ) : (
        <LoginWrapper>
          <PageContentWrapper withoutPadding={true} withoutBgColor={true}>
            <LoginBox>
              <ProductSelector
                isLoginPage={true}
                visible={true}
                maxHeight="100%"
              />
            </LoginBox>
          </PageContentWrapper>
        </LoginWrapper>
      )}
    </Page>
  );
};

export default ProductWizard;
