import styled from 'styled-components';

export const PageContent = styled.div<{
  wide?: boolean;
  width?: string;
  alignMent?: string;
}>`
  flex: 1;
  flex-direction: column;
  display: flex;
  max-width: 100%;
  width: ${({ width }) => width && width};
  align-self: ${({ wide, alignMent }) =>
    !wide && alignMent ? alignMent : 'center'};
  position: relative;
`;
