import * as React from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  children: React.ReactChild;
  id: string;
  className?: string;
};

export default function Portal({ children, id, className }: PortalProps) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    ref.current = document.querySelector(id);
    className && ref.current.classList.add(className);
    setMounted(true);
  }, [id, className]);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
}
