import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from '../hooks/useTranslation';
import { SidebarWrapper } from './layout/SidebarWrapper';
import { getColorFromType } from '../helpers/alarms';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => `${theme.space.xxxs} 0px`};
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div<{ checked: boolean }>`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  line-height: 24px;
  font-weight: ${({ checked }) => (checked ? 700 : 400)};
`;

const FilterIcon = styled.img`
  width: 17px;
  position: absolute;
`;

const AlarmLabel = styled.div<{ lightText: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.space.xs};
  border-radius: 1px;
  text-transform: uppercase;
  width: 220px;
  font-size: 11px;
  line-height: 12.65px;
  letter-spacing: 0.04em;
  color: ${({ theme, lightText }) =>
    lightText ? '#fff' : theme.colors.quintusTrueBlack};

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    flex: 1;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    flex: 0.3;
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 10px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 10px;
`;

const CheckBox = styled.div<{ checked: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 1px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.quintusDarkBlue};
  margin-right: 12px;
  z-index: 999;
  background-color: ${({ theme, checked }) =>
    checked && theme.colors.quintusDarkBlue};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

type FilterItemProps = {
  filter: Quintus.SearchFilter;
  updateSearchFilters(filter: Quintus.SearchFilter): void;
  checked: boolean;
};

const FilterItem: React.FC<FilterItemProps> = ({
  filter,
  updateSearchFilters,
  checked,
}) => {
  const { colors } = useTheme();

  return (
    <ItemWrapper onClick={() => updateSearchFilters(filter)}>
      <CheckboxWrapper>
        <CheckBox checked={checked} />
        <HiddenCheckbox />
      </CheckboxWrapper>
      {['Alarm', 'Warning', 'Information'].includes(filter.value) ? (
        <AlarmLabel
          style={{ backgroundColor: colors[getColorFromType(filter.value)] }}
          lightText={filter.value === 'Alarm'}
        >
          <FilterIcon src={`/images/${filter.value}-filter.svg`} />
          <div
            style={{
              marginLeft: 24,
              wordBreak: 'break-word',
            }}
          >
            {filter.label}
          </div>
        </AlarmLabel>
      ) : (
        <Label checked={checked}>{filter.label}</Label>
      )}
    </ItemWrapper>
  );
};

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 24px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.quintusTrueBlack};
  font-weight: 700;
  text-transform: uppercase;
  padding-right: ${({ theme }) => theme.space.xxs};
  padding-bottom: 13px;
  border-bottom-width: 2px;
  border-bottom-color: ${({ theme }) => theme.colors.quintusDarkBlue};
  margin-bottom: ${({ theme }) => theme.space.s};
`;

const StyledSidebarWrapper = styled(SidebarWrapper)`
  padding: ${({ theme }) => `${theme.space.s} ${theme.space.xl} 0px 10px`};

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ${({ theme }) => theme.space.m};
  }
  @media (max-width: ${({ theme }) => theme.screens.l}) {
    padding: ${({ theme }) => `${theme.space.s} ${theme.space.s} 0px 10px`};
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    padding: 0;
    margin-top: ${({ theme }) => theme.space.s};
    margin-bottom: ${({ theme }) => theme.space.xxs};
    width: 100%;
  }
`;

type FiltersProps = {
  showFilters?: boolean;
  filters: Quintus.SearchFilter[];
  updateActiveFilters(filters: Quintus.SearchFilter[]): void;
  activeFilters: Quintus.SearchFilter[];
};

const Filters: React.FC<FiltersProps> = ({
  showFilters,
  filters,
  updateActiveFilters,
  activeFilters,
}) => {
  const { t } = useTranslation();

  const updateSearchFilters = React.useCallback(
    (filter: Quintus.SearchFilter) => {
      const newFilters = [...activeFilters];
      const existingFilter = newFilters.find(f => f.value === filter.value);

      if (existingFilter) {
        newFilters.splice(newFilters.indexOf(existingFilter), 1);
      } else {
        newFilters.push(filter);
      }

      updateActiveFilters(newFilters);
    },
    [activeFilters, updateActiveFilters],
  );

  if (filters.length < 1) {
    return null;
  }

  return (
    <StyledSidebarWrapper>
      {showFilters && (
        <>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Title>{t('search.filtersTitle')}</Title>
          </div>
          <div>
            {filters.map((filter, index) => (
              <FilterItem
                filter={filter}
                key={index}
                updateSearchFilters={updateSearchFilters}
                checked={
                  activeFilters.find(af => af.value === filter.value)
                    ? true
                    : false
                }
              />
            ))}
          </div>
        </>
      )}
    </StyledSidebarWrapper>
  );
};

export default Filters;
