import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'src/hooks/useTranslation';

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.1px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.quintusGray};
  margin-bottom: ${({ theme }) => theme.space.s};

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    margin-top: ${({ theme }) => theme.space.s};
  }
`;

const ResultsCount: React.FC<{ count: number }> = ({ count }) => {
  const { t } = useTranslation();
  return <Wrapper>{`${count} ${t('search.searchResultsCount')}`}</Wrapper>;
};

export default ResultsCount;
