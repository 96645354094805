import * as React from 'react';
import Modal from './Modal';
import H2 from '../typography/H2';
import styled from 'styled-components';
import Button from '../Button';
import { useTranslation } from '../../hooks/useTranslation';
import { GAEvent } from '../../helpers/analytics';

const Wrapper = styled.div`
  padding: 0 16px 16px;

  & h2 {
    font-size: ${({ theme }) => theme.fontSizes.modal};
    color: ${({ theme }) => theme.colors.quintusBlue};
    font-style: normal;
    font-weight: 400;
    line-height: ${({ theme }) => theme.lineHeights.modal};
    letter-spacing: 0.05em;
    margin: ${({ theme }) => theme.space.modal} 0;
  }
`;

const Options = styled.div`
  display: 'flex';
  flex-direction: column;

  & button {
    width: 100%;
    padding: ${({ theme }) => theme.space.md};
    font-size: ${({ theme }) => theme.fontSizes.ml};
    line-height: ${({ theme }) => theme.lineHeights.xxs};

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
`;

type FeedbackModalProps = {
  isVisible: boolean;
  closeHandler: (feedbackProvided?: boolean) => void;
};

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isVisible,
  closeHandler,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      width={'540px'}
      isVisible={isVisible}
      onClose={() => closeHandler(false)}
    >
      <Wrapper>
        <Options>
          <H2>{t('feedback.modalTitle')}</H2>
          <Button
            text={t('feedback.optionA')}
            onClick={() => {
              GAEvent(
                'Feedback',
                'Provided negative feedback: ' + t('feedback.optionA'),
              );
              closeHandler(true);
            }}
          />
          <Button
            text={t('feedback.optionB')}
            onClick={() => {
              GAEvent(
                'Feedback',
                'Provided negative feedback: ' + t('feedback.optionB'),
              );
              closeHandler(true);
            }}
          />
          <Button
            text={t('feedback.optionC')}
            onClick={() => {
              GAEvent(
                'Feedback',
                'Provided negative feedback: ' + t('feedback.optionC'),
              );
              closeHandler(true);
            }}
          />
        </Options>
      </Wrapper>
    </Modal>
  );
};

export default FeedbackModal;
