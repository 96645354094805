import * as React from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import { getToken } from '../../services/api';
import { useInternalLink } from 'src/hooks/useInternalLink';
import { useFetchBrowse } from 'src/hooks/useFetchData';
import { useLinkFromId } from 'src/helpers/link';
import { useConfig } from 'src/hooks/useAuth';
import { Link } from 'react-router-dom';

const pointSize = 42.35;

const HotspotPoint = styled.img<{ left: string; top: string }>`
  position: absolute;
  width: ${pointSize}px;
  height: ${pointSize}px;
  left: ${({ left }) => `${parseFloat(left) * 100}%`};
  top: ${({ top }) => `${parseFloat(top) * 100}%`};
  transform: translate(-${pointSize / 2}px, -${pointSize / 2}px);
  cursor: pointer;
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.9);
  padding: ${({ theme }) =>
    `${theme.space.xs} ${theme.space.s} ${theme.space.xs} ${theme.space.xs}`};
  color: ${({ theme }) => theme.colors.quintusDarkBlue};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.xxs};

  &:after {
    background: url(/images/arrow.svg);
    background-size: contain;
    content: '';
    width: 12px;
    height: 7px;
    background-repeat: no-repeat;
    margin-left: ${({ theme }) => theme.space.s};
    align-self: center;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.quintusGreen};

    &:after {
      background: url(/images/arrow-active.svg);
      background-size: contain;
      content: '';
      background-repeat: no-repeat;
    }
  }
`;

const PopoverLink = styled(Link)`
  display: block;
  cursor: pointer;
  padding-bottom: 40px;
  margin-bottom: -40px;
`;

type HotspotPopoverProps = {
  point: Quintus.Point;
  parentElement: HTMLElement;
};

const HotspotPopover: React.FC<HotspotPopoverProps> = ({
  point,
  parentElement,
}) => {
  const [opened, setOpened] = React.useState(false);
  const { internalLink } = useInternalLink();
  const { data } = useFetchBrowse();
  const idLink = useLinkFromId(data?.data, point.link);

  const getLink = () => {
    const link =
      point.type === 'hotspot'
        ? internalLink(`/hotspot/${point.link}`)
        : idLink;

    return link;
  };

  return (
    <>
      <Popover
        align="start"
        isOpen={opened}
        content={
          <PopoverLink
            to={getLink()}
            onMouseEnter={() => setOpened(true)}
            onMouseLeave={() => setOpened(false)}
          >
            <PopoverContent>{point.text}</PopoverContent>
          </PopoverLink>
        }
        parentElement={parentElement}
        onClickOutside={() => setOpened(false)}
        containerStyle={{
          top: '-5px',
          left: '15px',
        }}
      >
        <HotspotPoint
          src={
            opened ? '/images/hotspot-active.svg' : '/images/hotspotpoint.svg'
          }
          left={point.x}
          top={point.y}
          onMouseEnter={() => setOpened(true)}
          onMouseLeave={() => setOpened(false)}
        />
      </Popover>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

type HotspotImageProps = {
  image: Quintus.HotspotImage;
};

const HotspotImage: React.FC<HotspotImageProps> = ({ image }) => {
  const imageRef = React.useRef<HTMLImageElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState<number>();
  const config = useConfig();

  const onImageLoad = () => {
    setWidth(imageRef.current.width);
  };

  return (
    <Wrapper
      ref={wrapperRef}
      style={{
        width: '100%',
      }}
    >
      <img
        onLoad={onImageLoad}
        ref={imageRef}
        src={`${config?.configuration?.storage_url}images/${
          image.imageId
        }?${getToken('storageToken')}`}
        style={{
          width: '100%',
        }}
      />
      {image.points.map((point, index) => (
        <HotspotPopover
          parentElement={wrapperRef.current}
          point={point}
          key={index}
        />
      ))}
    </Wrapper>
  );
};

export default HotspotImage;
