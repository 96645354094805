import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const baseListStyling = css`
  li {
    padding: 0;
    font-size: ${({ theme }) => theme.fontSizes.m};
    font-weight: 400;
    line-height: ${({ theme }) => theme.lineHeights.m};
    margin-bottom: ${({ theme }) => theme.space.xs};
  }
`;

const LegendStyle = css`
  padding-left: ${({ theme }) => theme.space.l};
  counter-reset: item;

  > li {
    position: relative;
    margin-bottom: -${({ theme }) => theme.space.xxs};

    &::before {
      position: absolute;
      left: -${({ theme }) => theme.space.gutter};
    }
  }
`;

const AlphaLegendStyles = css`
  > li {
    &::before {
      counter-increment: item;
      content: counter(item, upper-latin) '.';
    }
  }
`;

const NumericLegendStyles = css`
  counter-reset: main-count;

  > li {
    counter-increment: main-count;
  }
`;

const StepsStyle = css`
  list-style: decimal;
`;

const Ul = styled.ul`
  ${baseListStyling}
  padding-left: ${({ theme }) => theme.space.l};

  li {
    list-style: disc;
  }
`;

type OlTypes = {
  alphaLegend: boolean;
  numericLegend: boolean;
  steps: boolean;
};

const SubTaskStyle = css`
  counter-reset: sub-item;

  > li {
    counter-increment: sub-item;
    &::before {
      margin-right: ${({ theme }) => theme.space.xs};
      float: left;
      content: counter(main-count) '.' counter(sub-item) ' ';
    }
  }
`;

const SubOl = styled.ol`
  ${baseListStyling}
  ${SubTaskStyle}

  padding-left: ${({ theme }) => theme.space.m};

  counter-reset: sub-item;
`;

const Ol = styled.ol<OlTypes>`
  ${baseListStyling}
  ${NumericLegendStyles}

  padding-left: ${({ theme }) => theme.space.m};

  list-style: decimal;

  li {
    padding-left: ${({ theme }) => theme.space.xs};
  }

  ${({ alphaLegend, numericLegend }) =>
    (alphaLegend || numericLegend) && LegendStyle};
  ${({ alphaLegend }) => alphaLegend && AlphaLegendStyles};
  ${({ numericLegend }) => numericLegend && NumericLegendStyles};
  ${({ steps }) => steps && StepsStyle};

  ul {
    li {
      list-style: disc;
    }
  }
`;

type ListProps = {
  node: Quintus.Topic;
  children: React.ReactNode;
};

const List: FC<ListProps> = ({ node, children }) => {
  const { name, attribs } = node;

  const isNested = node.parent && node.parent.name === 'li';

  switch (name) {
    case 'ul':
      return <Ul className={attribs.class}>{children}</Ul>;

    case 'ol': {
      if (isNested) {
        return <SubOl className={attribs.class}>{children}</SubOl>;
      }

      return (
        <Ol
          className={attribs.class}
          alphaLegend={attribs.class === 'alpha-legend'}
          numericLegend={attribs.class === 'numeric-legend'}
          steps={attribs.class === 'steps'}
        >
          {children}
        </Ol>
      );
    }

    default:
      return <ul>{children}</ul>;
  }
};

export default List;
